import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { getCategories, getCities, getCityFromCoordinates } from '../../../service/CategoryServices'
import BigButton from '../../UI/BigButton'
import CalendarSearch from '../CalendarSearch'
import FilterBox from '../FilterBox'
import LocationSearch from '../LocationSearch'
// import standardSlugify from "standard-slugify";
import { getAutocomplete } from '../../../service/CategoryServices'
import { useTranslation } from 'react-i18next'
import './SearchMain.scss'

/**
 * It's a function that takes in a bunch of props and returns a component that renders a search bar
 * @returns A function that takes a value as an argument and returns a function that takes a value as
 * an argument and returns a function that takes a value as an argument
 */
const SearchMain = ({
    className,
    defaultPlaceholders,
    onFilterChange = console.log,
    stopParent = 'home-search',
    categories,
    cities,
    confirm,
    popupFollowStep,
    onOpen
}) => {

    const history = useHistory()
    const { t, i18n } = useTranslation()
    const fromHistory = history.location.state || {}

    /* Checking if the dates property of the fromHistory object is an array. If it is not, it creates
    an empty array and pushes the date and datefin properties of the fromHistory object into the
    array. */
    if (!(fromHistory.dates instanceof Array)) {
        fromHistory.dates = []
        fromHistory.date && fromHistory.dates.push(new Date(fromHistory.date))
        fromHistory.datefin && fromHistory.dates.push(new Date(fromHistory.datefin))
    }

    /* Setting the initial state of the city variable to the value of the city in the history object, or
    the last city searched in local storage, or undefined. */
    const [city, setCity] = useState(fromHistory.city || localStorage.getItem('tnj_last_city_searched') || undefined)
    const [citiesComplete, setCities] = useState(cities)
    const [category, setCategory] = useState(fromHistory.category)
    const [inevitable, setInevitable] = useState(fromHistory.inevitable)
    const [dates, setDates] = useState(fromHistory.dates)
    const [step, setStep] = useState(0)
    const [popupFollowing, setPopupFollowing] = useState({})
    /* Assigning the default placeholders to the placeholders variable. */
    const placeholders = Object.assign([t('search.filter.ou'), t('search.filter.quoi'), t('search.filter.quand')], Array.isArray(defaultPlaceholders) ? defaultPlaceholders : [])

    /* Setting the default placeholders for the city and category inputs. */
    useEffect(() => {
        if (!defaultPlaceholders) return
        defaultPlaceholders[0] && setCity(defaultPlaceholders[0])
        defaultPlaceholders[1] && setCategory(defaultPlaceholders[1])
    }, [defaultPlaceholders])

    /**
     * It sets the step to 1 if the confirm and city are false, otherwise it sets the step to the
     * state.
     */
    const currentState = state => requestState => {
        let authorizeState = !confirm && !city ? 1 : state
        setStep(requestState ? authorizeState : 0)
    }

    /* Adding an event listener to the document that will listen for clicks. If the click is outside of
    the element with the class stopParent, it will set the step to 0. */
    useEffect(() => {
        /**
         * If the user clicks outside of the modal, close the modal
         * @returns A function that is called when the user clicks on the page.
         */
        const onClick = e => {
            if (!step) return
            let ok = undefined
            let element = document.elementFromPoint(e.clientX, e.clientY)
            while (ok !== true && ok !== false) {
                if (!element || element.id == 'root') ok = false
                else if (element.classList && element.classList.contains(stopParent)) ok = true
                else element = element.parentNode
            }
            !ok && setStep(0)
        }
        /* Adding an event listener to the document object. When the document is clicked, the function onClick
        will be called. */
        document.addEventListener('click', onClick)
        /* Creating a function that returns a function. */
        return () => document.removeEventListener('click', onClick)
    }, [step])

    /* Using the useEffect hook to check if the openSearch prop is true. If it is, it will wait 400ms and
    then set the step to 1. */
    useEffect(() => fromHistory.openSearch && setTimeout(() => setStep(1), 400), [])

    /* Setting the cities state to the cities array. */
    useEffect(() => setCities(cities), [cities])

    /* Adding a class to the hero element when the step is true and removing it when the step is false. */
    useEffect(() => {
        const hero = document.querySelectorAll('.home-hero-inner')
        if (hero) {
            hero.forEach(a => a.classList[step ? 'add' : 'remove']('low-opacity'))
            // document.body.classList[step ? 'add' : 'remove']('block-scroll')
            return () => { hero.forEach(a => a.classList.remove('low-opacity')); /*document.body.classList.remove('block-scroll')*/ }
        }
    }, [step])

    /* Calling the onOpen function if it exists and passing in the value of step. */
    useEffect(() => onOpen?.(!!step), [step])

    /* A callback function that is used to autocomplete the city name. */
    const cityAutocomplete = useCallback(value => {
        /* Filtering the cities array based on the value of the input field. */
        if (!value) return setCities(cities)
        /* Getting the autocomplete from the API and then setting the cities to the result. */
        getAutocomplete(value)
            .then(res =>
                /* Filtering the cities array based on the value entered by the user. */
                setCities(
                    res.data.reduce((prev, cur) => {
                        /* Checking if the current display name is already in the array. If it is, it returns the previous
                        array. */
                        if (prev.find(a => a.name.toLowerCase() === cur.display_name.toLowerCase().split(',')[0])) return prev
                        /* Finding the city that matches the city name in the current object. */
                        const preSelectingCity = cities.find(a => a.name.toLowerCase() === cur.display_name.toLowerCase().split(',')[0])
                        /* A reducer function that takes in the previous value and the current value
                        and returns the new value. */
                        return [...prev, (preSelectingCity || {
                            name: cur.display_name.split(',')[0],
                            picture: "",
                            coordinate: {
                                lat: parseInt(cur.lat),
                                lng: parseInt(cur.lon)
                            }
                        })]
                    }, /* Filtering the cities array to only include cities that start with the value
                    and are longer than the value. */
                        cities.filter(a => a.name.length >= value && a.name.toLowerCase().startsWith(value.toLowerCase())))
                )
            )
    }, [cities])

    /**
     * It's a function that takes a value as an argument and returns a function that takes a value as
     * an argument and returns a function that takes a value as an argument
     * @returns 
     */
    const next = value => {
        if (!step) return
        switch (step) {
            /*  */
            case 1: {
                onFilterChange({ city: value })
                setCity(value || "")
                if (!confirm) {
                    if (category !== undefined && dates.length) {
                        setStep(0)
                        // setTimeout(() => redirect({ city: value || "" }), 400)
                    } else {
                        if (category === undefined) return setStep(2)
                        setStep(3)
                    }
                } else {
                    setStep(0)
                }
                break
            }
            /*  */
            case 2: {
                if (value.inevitable || !value.category) {
                    onFilterChange({ category: false, inevitable: true })
                    setCategory(false)
                    setInevitable(true)
                } else if (value.category && value.category.name?.toLowerCase() === "live event") {
                    onFilterChange({ city: null, category: value.category, inevitable: false })
                    setCategory(value.category?.name)
                    setInevitable(false)
                    setCity("France")
                } else {
                    onFilterChange({ category: value.category, inevitable: false })
                    setCategory(value.category?.name)
                    setInevitable(false)
                }

                if (!confirm) {

                    if (city && dates.length) {
                        setStep(0)
                        // setTimeout(() => redirect({ category: value || null }), 400)
                    } else {
                        if (!dates.length) return setStep(3)
                        setStep(1)
                    }
                } else {
                    setStep(0)
                }
                break
            }
            /* Setting the dates and then calling the onFilterChange function. */
            case 3: {
                setDates(value[0])
                /* Setting the state of the date and datefin to the value of the datepicker. */
                onFilterChange({ date: value[0][0].toISOString(), datefin: value[0][1].toISOString() })
                /*  */
                if (!confirm) {
                    if (!city) return setStep(1)
                    else if (category === undefined) return setStep(2)
                    setStep(0)
                } /* Checking if the user is on a mobile device. If they are, it will set the step to
                0. If they are not, it will set the step to 0 after 800 milliseconds. */
                else {
                    setTimeout(() => setStep(0), 800)
                }
                break
            }
        }
    }

    /* A switch statement that returns a function based on the value of the step variable. */
    const FilterPage = useMemo(() => {
        switch (step) {
            /* A switch statement that returns a function that returns a component. */
            case 1: return p => <LocationSearch {...p} items={citiesComplete} next={next} />
            case 2: return p => <LocationSearch {...p} items={categories} type={false} next={next} cityFilter={city} />
            case 3: return p => {
                return <>
                    <CalendarSearch {...p}
                        language={i18n.language}
                        initDates={dates && dates[0] && dates.length > 1 ? [dates] : []}
                        creatingPlageWithDate={dates && dates.length === 1 ? [dates[0], dates[0]] : undefined}
                        next={next}
                        onNewPlageCreated={a => next([a])}
                        // onNewPlageCreated={value => setDates(value) || onFilterChange({ date: value[0].toISOString(), datefin: value[1].toISOString() }) || setStep(a => a + 1) || true}
                        skip={next}
                        onNewDateSelected={value => console.log('DATE', value, dates) || setDates([value]) || true} />
                </>
            }
            default: return () => <></>
        }
    }, [step, citiesComplete, dates, categories])

    /**
     * It redirects to the events page with the given parameters
     */
    const redirect = ff => {
        /* Checking if the user has confirmed their city and if they have not, it will return them to step 1. */
        if (!confirm && !city) return setStep(1)
        const ii = ff.inevitable || inevitable
        const cat = ff.category || category
        const cit = ff.city || city
        const dd = ff.dates || dates
        /* Creating a query string for the API call. */
        const query = (cat ? `&category=${cat.toLowerCase()}` : ii ? '&inevitable=true' : '')
            + (typeof cit === 'string' ? `&city=${cit}` : cit ? `&coordinate=${cit.lat}:${cit.lng}` : "").toLowerCase()
            + (dd && dd[0] ? `&date=${dd[0].toISOString()}&datefin=${(dd[1] || dd[0]).toISOString()}` : "")
        /* Pushing the current page to the history stack and then redirecting to the events page with the query
        parameters. */
        history.push('/evenements?' + query.slice(1), { city: cit, category: cat, dates: dd, step, inevitable: ii })
    }

    /* A React Hook that is used to update the position of a popup based on the current step of the
    popup. */
    useEffect(() => {
        /* Checking if the popupFollowStep is true, if it is not true, it will return the popupFollowing and
        setPopupFollowing to an empty object. */
        if (!popupFollowStep) return !popupFollowing && setPopupFollowing({})
        const filterPopup = document.querySelector('.filters-popup'),
            filterBar = document.querySelector('.filters')
        let transition = 'all 0.4s'
        /* Setting the transition property to all 0s if the popupFollowing.right is equal to the
        filterBar.getBoundingClientRect().width. */
        if (popupFollowing.right === filterBar.getBoundingClientRect().width)
            transition = 'all 0s'
        /* Setting the position of the popup. */
        switch (step) {
            case 2: {
                const popupWidth = 688//filterPopup.getBoundingClientRect().width
                /* Getting the width of the filter bar. */
                const totalWidth = filterBar.getBoundingClientRect().width
                /*  */
                return setPopupFollowing({
                    left: 'min(22.72% , ' + Math.max(0, totalWidth - popupWidth) + 'px )',
                    right: (totalWidth - popupWidth) > (totalWidth / 4.4) ? (totalWidth - popupWidth) - (totalWidth / 4.4) : undefined,
                    transition
                })
            }
            case 3: {
                const popupWidth = 688//filterPopup.getBoundingClientRect().width
                const totalWidth = filterBar.getBoundingClientRect().width
                return setPopupFollowing({
                    right: (totalWidth - popupWidth) > (totalWidth / 4.4) ?
                        Math.max(0, (totalWidth - popupWidth) - (totalWidth / 2.2)) :
                        undefined,
                    left: 'min(45.44% , ' + Math.max(0, totalWidth - popupWidth) + 'px )',
                    transition
                })
            }
            /* Setting the popupFollowing to the width of the filterBar. */
            case 0: return setPopupFollowing({ right: filterBar.getBoundingClientRect().width })
            /* Setting the position of the popup to the right of the filter bar. */
            default: {
                const totalWidth = filterBar.getBoundingClientRect().width
                const popupWidth = 560//filterPopup.getBoundingClientRect().width
                return setPopupFollowing({ right: Math.max(0, totalWidth - popupWidth), transition })
            }
        }
    }, [step])

    /* A React component that is being rendered. */
    return (
        <>
            {/* A React component that is being rendered. */
            popupFollowing && <div
                className={(className ? className + '-popup' : '') + " filters-popup " + (step ? '' : ' hidden')}
                style={popupFollowing}
            >
                <FilterPage
                    checkedFilter={[false, city, { name: category }][step]}
                    specialcheckedFilter={[false, false, !category][step]}
                />
            </div>}
            <div className={(className || '') + ' filters'} onClick={e => e.target.tagName.toLowerCase() !== 'input' && setStep(0)}>
                <FilterBox onTimedChange={cityAutocomplete} key={city} current={step === 1} placeholder={city ? typeof city === 'string' ? city : 'Autour de moi' : placeholders[0] || ""} changeCurrentState={currentState(1)} label={t('search.placeholder.ou')} />
                <FilterBox current={step === 2} placeholder={inevitable === true ? t("search.input.surprise") : category === false ? 'Categories' : category || placeholders[1] || ""} changeCurrentState={currentState(2)} label={t('search.placeholder.quoi')} />
                <FilterBox current={step === 3} date placeholder={dates && dates[0] instanceof Date ? dates : placeholders[2] || ""} changeCurrentState={currentState(3)} label={t('search.placeholder.quand')} />
                <button type="button" className={"loupe " + (city || true ? "main" : "")} onClick={e => e.stopPropagation() || redirect({})}>
                    <span>{t('searchBar.rechercher')}</span>
                    <img src='/img/loupe.svg' alt="" width="19" height="19" className='light' />
                    <img src='/img/loupe-green.svg' alt="" width="19" height="19" className='dark' />
                </button>
            </div>
        </>
    )
}
export default SearchMain