import React from 'react'
import './rubber.scss'

const Rubber = ({ text = "TOP TENDANCE" }) => {
    return (
        <>
            <div className="rubber-front">
                {text.toUpperCase()}
            </div>
            <div className="rubber-back" />
        </>
    )
}

export default Rubber;