import React, { useRef } from 'react'
import { parseISODate } from '../../../service/SeoService'
import './Filterbox.scss'
import SearchInput from '../../UI/Inputs/SearchInput'

/**
 * It renders a box that can be clicked on to open a dropdown menu
 * @returns A div with a label and an input.
 */
const FilterBox = ({ label, date = false, placeholder, current, changeCurrentState, onTimedChange }) => {
    const input = useRef(null)

    /**
     * If the user clicks on the input, the dropdown will open. If the user clicks anywhere else, the
     * dropdown will close
     */
    const onClick = e => {
        e.stopPropagation?.()
        if (current && (e.target !== input.current)) changeCurrentState(false)
        else changeCurrentState(true)
    }

    /* A ternary operator. */
    const displayedValue = date ?
        placeholder instanceof Date ?
            parseISODate(placeholder, placeholder, false) :
            placeholder instanceof Array ?
                parseISODate(placeholder[0], (placeholder[1] || placeholder[0]), false).replaceAll(/ \d{4}/g, '') :
                placeholder :
        placeholder.slice(0, 1).toUpperCase() + placeholder.slice(1).toLowerCase()

    /* Returning a div with a className of 'filters-box' and 'current' if the current state is true. It
    also has an onClick event that calls the onClick function. */
    return (
        <div className={'filters-box' + (current ? ' current' : '')} onClick={onClick}>
            <div>
                <span>
                    {
                        /* A ternary operator. If onTimedChange is true, it will render the SearchInput
                        component. If it is false, it will render the span. */
                        onTimedChange ?
                            <SearchInput
                                onClick={onClick}
                                placeholder={displayedValue}
                                ref={input}
                                onTimedChange={onTimedChange}
                                showControl={current}
                            /> :
                            <span ref={input} onClick={onClick} className='input'>{displayedValue}</span>
                    }
                    <img src='/img/down-arrow.svg' />
                </span>
                <label className="label">{label.toUpperCase()}</label>
            </div>
        </div>
    )
}

export default FilterBox