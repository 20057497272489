import ApiSails from '../config/ApisailsRequest';
import { APISAILS_URL } from "../config/apiUrl"
import standardSlugify from "standard-slugify";

const distanceParCity = city => {
    switch (city.toLowerCase()) {
        case 'nice': return 50
        default: return 20
    }
}

export const getSailsEventsCount = (city, categories) => {
    return ApiSails.get(`/event?page=1&perPage=1${city ? "&city=" + city.toString().toUpperCase() + "&distance=" + distanceParCity(city) : ""}&olds=false${Array.isArray(categories) ? `&categories=["${categories.join('","')}"]` : ""}`, {})
}

export const getSailsEvents = filters => {
    let query = "";
    query += "&page=" + (filters.page || "1")
    query += "&perPage=" + (filters.perPage || "10")
    filters.olds !== undefined && (query += "&olds=" + filters.olds)
    let populate = ["company_events", "coordinate", "categories", "tags", "pictures", "occurences"]
    if (filters.city || (filters.lng || filters.lat)) {
        if (filters.city !== "France") {
            query += "&city=" + filters.city
        } else {
            query += "&lat=" + filters.lat + "&lng=" + filters.lng
        }
        if (filters.city === "France") {
            query += "&distance=" + (filters.distance || "1500")

        }
        else {
            query += "&distance=" + (filters.distance || "20")
        }
    }
    filters.categories && populate.push("categories") && (query += "&categories=" + JSON.stringify(filters.categories))
    query += '&order=[["date","' + (filters.order ? "ASC" : "DESC") + '"]]'
    query += "&populate=" + JSON.stringify(populate)
    return ApiSails.getAuthToken().then(() => ApiSails.get('/event' + (query.length && query.replace("&", "?")), {})).then(response => {
        let formatted = { metaData: response.metaData };
        formatted.rows = response.rows.map(event => ApiSails.formatEvent(event))
        return formatted
    })
}
export const getEventById = async id => {
    return await ApiSails.get(`/event/${id}?populate=["coordinate","categories","tags",company_events","occurences"]`).then(a => ApiSails.formatEvent(a)).then(a => console.log("event", a) || a).catch(a => ({ error: a.message }))
}

const eventUrl = ({ city, coordinate, distance, number = 10, categories, inevitable, page = 1, date, datefin }) =>
    `/event?page=${page}&perPage=${number}&populate=["categories","coordinate","occurences","tags"]&olds=false${inevitable !== undefined ? `&inevitable=${!!inevitable}` : ""}&order=[[inevitableRate","DESC"],['occurences', 'datefin', 'ASC'],['occurences', 'date', 'ASC'],['datefin','ASC'],['date','ASC']]${coordinate && coordinate.lng && coordinate.lat ? `&lat=${coordinate.lat}&lng=${coordinate.lng}&distance=${distance || 20}` : city ? `&city="${city}"&distance=${distance || distanceParCity(city)}` : ""}${Array.isArray(categories) ? `&categories=["${categories.join('","')}"]` : ""}${date instanceof Date ? `&fromDate=${date.toISOString().replace(/Z/g, '')}&toDate=${new Date((datefin instanceof Date ? datefin.getTime() : date.getTime() + 31536000000) + 86400000).toISOString().replace(/Z/g, '')}` : ""}&where={\"published\":true}`

export const createPictureUrl = data => {
    return Array.isArray(data) ? data.map(a => {
        a.picture = `${APISAILS_URL}/event/${a.id}/getImage/${standardSlugify(a.title, { keepCase: false })}.jpeg`
        return a
    }) : Object.assign(data, { picture: `${APISAILS_URL}/event/${data.id}/getImage/${standardSlugify(data.title, { keepCase: false })}.jpeg` })
}

export const getFeaturedEvents = async ({ city, coordinate, distance, number = 20, categories, page, date, datefin, inevitable = true }) => {
    return await ApiSails.get(eventUrl({ city, coordinate, distance, number, categories, page, inevitable, date, datefin }))
        .then(({ rows }) => {
            let ROWS = [...rows]
            if (rows.length < number) {
                let restants = number - rows.length
                return ApiSails
                    .get(eventUrl({ city, coordinate, distance, number: restants, categories, inevitable: false, date, datefin }))
                    .then(({ rows }) => {
                        ROWS.push(...rows.slice(0, restants))
                        return ROWS
                    })
                    .catch(err => {
                        console.log(err)
                        return ROWS
                    })
            }
            return ROWS
        })
        .then(createPictureUrl)
        .catch(err => {
            console.log(err)
            return false
        })
}

export function setLike(eventId, userId, value) {
    let promise = new Promise((resolve, reject) => {
        let body = {
            event_id: eventId,
            value: value
        };
        var route = "/user/" + userId + "/eventsetlike";
        ApiSails.post(route, body)
            .then(data => {
                resolve(true);
            })
            .catch((err) => {
                console.log("error");
                console.log(err);
                reject(err);
            });
    });
    return promise;
}

export async function getFavoritesEvents(user_id, page, perPage) {
    let body = {
        page: page,
        perPage: perPage,
        olds: "false",
        order: "[[\"date\",\"ASC\"]]",
        populate: '["categories","coordinate","occurences"]',
    };
    var route = "/user/" + user_id + "/getEventFavorite";
    const test = await ApiSails.get(route, body)
        .then(data => {
            const obj = createPictureUrl(data.rows)
            return obj
        })
        .catch((err) => {
            console.warn(err)
            return err.message || err.error || err;
        });
    return test
}

export const infiniteEventFetch = async function* ({ city, coordinate, distance, number = 7, categories, page = 1, inevitable, date, datefin }) {
    let i = page
    let events = []
    if (categories?.includes("15463e4a-a8c3-4c84-b2f4-c4b46a3d2577")) {
        city = "France"
    }
    if (city == "France") {
        city = ""
        distance = "1500"
    }

    const push = () => events.push(ApiSails
        .get(eventUrl({ city, coordinate, distance, number, categories, page: i, inevitable, date, datefin }))
        .then(({ rows, metaData: { count, currentPage } }) => {
            i++
            return { rows: createPictureUrl(rows), count, page: currentPage, filters: { city, coordinate, distance, number, categories, page: i, inevitable, date, datefin } }
        })
        .catch(err => {
            console.error(err)
            return null
        })
    )
    push()
    i++
    while (true) {
        push()
        yield events[events.length - 2]
    }
}
const EventService = {
    getSailsEventsCount,
    getSailsEvents,
    getEventById,
    getFeaturedEvents,
    infiniteEventFetch,
    setLike
}
export default EventService
