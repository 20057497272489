

/**
 * Compare two GPS coordinates
 * @param {{lat:number,lng:number}} a 
 * @param {{lat:number,lng:number}} b 
 * @returns {number} distance in meter bewteen the 2 points
 */
export const compareLocation = (a, b) => {
    if (!a || !b) return -1
    const R = 6371e3; // metres
    const PI = Math.PI / 180
    const s1 = a.lat * PI; // φ, λ in radians
    const s2 = b.lat * PI;
    const dS = (b.lat - a.lat) * PI;
    const dL = (b.lng - a.lng) * PI;

    const d = Math.sin(dS / 2) * Math.sin(dS / 2) +
        Math.cos(s1) * Math.cos(s2) *
        Math.sin(dL / 2) * Math.sin(dL / 2);
    const c = 2 * Math.atan2(Math.sqrt(d), Math.sqrt(1 - d));

    return R * c; // in metres
}

/**
 * Return tile size in meters according to the zoom level
 * @type {Object<number,number>}
 * @constant
 */
export const scaleToMeters = {
    1: 591657550.500000,
    2: 295828775.300000,
    3: 147914387.600000,
    4: 73957193.820000,
    5: 36978596.910000,
    6: 18489298.450000,
    7: 9244649.227000,
    8: 4622324.614000,
    9: 2311162.307000,
    10: 1155581.153000,
    11: 577790.576700,
    12: 288895.288400,
    13: 144447.644200,
    14: 72223.822090,
    15: 36111.911040,
    16: 18055.955520,
    17: 9027.977761,
    18: 4513.988880,
    19: 2256.994440,
    20: 1128.497220,
}

/**
 * @typedef Location
 * @type {object}
 * @property {number} iat The time at witch the location was asked
 * @property {number} lng Longitude
 * @property {number} lat Latitude
 */

/** @type {Location} */
let location = {}

/**
 * Ask user for its location then store and return it for 1 hour
 * @async
 * @returns {Promise.<Location>}
 */
export default async function getLocation(success = console.log, failure = console.log) {
    if (location && location.iat > Date.now() - 36000000) {
        // One call every 15 minutes
        success(location)
        return location
    }
    else if (window.navigator.geolocation)
        await window.navigator.geolocation.getCurrentPosition(
            (pos) => {
                console.log(pos)
                location = {
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                    iat: pos.timestamp
                }
                sessionStorage.setItem("location", JSON.stringify(location))
                success(location)
            },
            () => {
                location = undefined;
                console.log("refused location")
                failure()
            }
        );
    return undefined
}