import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { userData } from "./user/reducers";
import { eventData } from "./events/reducers";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2
}

export const rootReducer = combineReducers({
  userData,
  eventData,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const devTool =
  process.env.NODE_ENV === "development" || window.location.origin.includes('timenjoy-dev.v3')
    ? (window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
    : compose;

export const store = createStore(persistedReducer,
  compose(
    applyMiddleware(ReduxThunk),
    devTool
  ))
export const persistor = persistStore(store)

export default store;

