import passwordInput from './PasswordInput'
import searchInput from './SearchInput'

/**
 * 
 * 
 * 
 * 
 * 
 * 
 */

const INPUTS = {
    PasswordInput: passwordInput,
    SearchInput: searchInput
}

export const PasswordInput = passwordInput
export const SearchInput = searchInput

export default INPUTS