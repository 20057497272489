import React, { useEffect, useState, useRef } from 'react'
import { getSessionInfo, ResetPassword, forgotPassword } from '../../service/UserServices/UserService'
import Footer from '../../component/Footer'
import TopBar, { JustButton } from '../../component/TopBar'
import './resetPassword.scss'
import { Link, useHistory } from 'react-router-dom'
import BigButton from '../../component/UI/BigButton'
import { PasswordInput } from '../../component/UI/Inputs'

const ResetPasswordView = ({ computedMatch: { params: { id } } }) => {
    const [user, setUser] = useState()
    const history = useHistory()
    const [isValid, setValid] = useState(false)
    const logRef = useRef(null)

    useEffect(() => {
        getSessionInfo(id)
            .then(({ user_id, expirationDate }) => {
                console.log(expirationDate, new Date(expirationDate).getTime(), Date.now(), new Date(expirationDate).getTime() > Date.now())
                setValid(new Date(expirationDate).getTime() > Date.now())
                setUser(user_id)
            })
            // .catch(a => console.error(a) || history.push('/', { openLogin: true }))
    }, [])

    const logMessage = (text, state = false) => {
        if (!logRef.current) return false
        logRef.current.innerHTML = text
        logRef.current.classList[state ? 'add' : 'remove']('success')
    }
    const resetPassword = e => {
        e.preventDefault()
        console.log("USER", user)
        const [{ value: password }, { value: checkPassword }] = e.target
        console.log(password, checkPassword)
        if(!password) logMessage("Veuillez saisir un mot de passe")
        if (password !== checkPassword) return logMessage("Les mots de passe ne correspondent passe")
        ResetPassword({ id: user, password, password_check: checkPassword })
            .then(() => logMessage("Votre mot de passe a été réinitialisé.", true))
            .catch(console.error)
    }
    const TOP = p => (<>
        <div className='filler' />
        <JustButton type='' {...p} />
    </>)

    if (!user) return <></>

    return (<>
        <TopBar className="reset-topbar" fixed>
            <TOP />
        </TopBar>
        <div className="resetPage">
            <div className="resetBox">
                {!isValid ?
                    <div>
                        <h2>Ce lien a expiré</h2>
                        <BigButton
                            className="submit-button"
                            text={"Renvoyer l'email"}
                            onClick={() => forgotPassword({ email: user.email })
                                .then(() => logMessage("Un nouvel email vous a été renvoyé.", true))
                                .catch(() => logMessage("Une erreur s'est produite. Veuillez réessayer ultérieurement."))}
                        />
                    </div> :
                    <>
                        <h2>Veuillez renseigner votre nouveau mot de passe</h2>
                        <form onSubmit={resetPassword} className="reset-password">
                            <PasswordInput autocomplete="off" placeholder="Nouveau mot de passe" onChange={()=>logMessage("")}/>
                            <PasswordInput autocomplete="off" placeholder="Confirmez le mot de passe" onChange={()=>logMessage("")}/>
                            <input type="submit" className='submit-button' />
                        </form>
                    </>
                }
            </div>
                <span ref={logRef} className="reset-logmessage" />
        </div>
        <Footer />
    </>)
}

export default ResetPasswordView