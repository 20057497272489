const initialState = {
  user: false
};

export const userData = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVE_USER": {
      return {
        ...state,
        user: action.data
      }
      
    }
    case "RESET_USER_DATA": {
      return {};
    }
    default:
      return state;
  }
};
