import React, { useState, useMemo, useEffect } from 'react'
import './calendarSearch.scss'
import { BigButton, SingleArrow } from '../../UI'

// TODO: think about internationalization
const dayNames = {
    fr: ['di', 'lu', 'ma', 'me', 'je', 've', 'sa', 'di'],
    en: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
}
const monthNames = {
    fr: [
        "janvier", "février", "mars", "avril", "mai", "juin",
        "juillet", "août", "septembre", "octobre", "novembre", "décembre"
    ],
    en: [
        "january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ]
}
const texts = {
    fr: { Passer: 'Passer', Suivant: 'Suivant' },
    en: { Passer: 'Skip', Suivant: 'Next' },
}
const aDayInUnix = 86400000
/**
 * Given a unix timestamp, return the unix timestamp of the start of the day.
 */
const UNIXDay = unix => unix - Math.ceil(unix % aDayInUnix)

/**
 * Compare the date to the plage and return a number
 * @param {Date} date the date to compare to the plage. Either a Date Object or a unix date (number)
 * @param {Array<Date>} param1 the start and end date of the plage processed with UNIXDay function
 * @returns {number} 2 if at the end of plage, 1 if at start, 3 if in plage, -1 if out of plage and 0 if in a one-day plage
 * @see UNIXDay
 */
const compareToPlage = (date, [start, end]) => {
    let theDate = date;
    let difStart = UNIXDay(theDate) - UNIXDay(start) // positif si date after start
    let difEnd = UNIXDay(end) - UNIXDay(theDate) // positif si date before end
    if (!difStart && !difEnd) return 0
    if (!difStart) return 1
    if (!difEnd) return 2
    if (difStart > 0 && difEnd > 0) return 3
    return -1
}

/**
 * It renders a calendar
 * @returns A React component
 */
const CalendarSearch = ({
    initDates = [],//[[new Date(2021, 2, 26), new Date(2021, 3, 2)]],
    amplitude = 12,
    onNewDateSelected = () => true,
    onNewPlageCreated = () => true,
    plageable = true,
    next = a => console.log('this is the new plage', ...a),
    skip = console.log,
    mobile,
    unique = true,
    className,
    defaultPage = 0,
    creatingPlageWithDate = [],
    language = 'fr'
}) => {
    let [dates, setDates] = useState(initDates)
    let [newPlage, createPlage] = useState(creatingPlageWithDate)
    let [page, navigateTo] = useState(mobile ? 0 : defaultPage)
    const today = useMemo(() => new Date(), [true])

    /* A React hook that returns the current month. */
    const currentMonth = useMemo(() => today.getMonth(), [today])
    /* A React hook that returns the current year. */
    const currentYear = useMemo(() => today.getFullYear(), [today])
    /* It creates an array of the next 12 months. */
    const months = useMemo(() => new Array(Math.min(amplitude, 12)).fill(1).map((_, i) => i + currentMonth), [amplitude])

    /**
     * It creates a new date, checks if it's valid, and if it is, it either creates a new plage or adds
     * the date to the list of dates
     * @param year - the year of the date
     * @param month - the month to display
     * @param day - the day of the month
     * @returns A function that takes a year, month, and day as arguments.
     */
    const onDateClick = (year, month, day) => () => {
        let theDate = new Date(Date.UTC(year, month, day))
        /* It's a callback that is called when a date is selected. It's used to prevent the selection of a
        date. */
        if (!onNewDateSelected(theDate)) return
        let remove
        /* It's a callback that is called when a date is selected. It's used to prevent the selection
        of a
        date. */
        if (!plageable)
            return next(theDate)
        /* It's checking if the date is in a plage, and if it is, it removes the plage from the list of
        plages. */
        if (remove = dates.find(a => compareToPlage(theDate, a) !== -1))
            return setDates(a => a.filter(b => b !== remove))
        /* It's checking if the user is creating a new plage. If he is, it creates a new plage with the
        date. */
        if (!newPlage[0]) {
            unique && setDates([])
            createPlage([theDate, theDate])
        } /* It's creating a new plage. */
        else {
            const plage = newPlage[0] <= theDate ? [newPlage[0], theDate] : [theDate, newPlage[0]]
            if (!onNewPlageCreated(plage)) return
            createPlage([])
            /* It's checking if the user wants to select only one plage. If he does, it removes all the plages from
            the list of plages. If he doesn't, it removes all the plages that overlap with the new plage. */
            unique ? setDates([plage]) : setDates(a => a.filter(b => compareToPlage(b[0], plage) === -1 && compareToPlage(b[1], plage) === -1).concat([plage])) // TODO : prevent plage overlapping
        }
    }

    /* It's creating an array of the number of days in each month. */
    const nbOfDay = useMemo(() => months.map(a => {
        switch ((a % 12) + 1) {
            /* It's checking if the month has 31 days. */
            case 1: case 3: case 5: case 7:
            case 8: case 10: case 12: {
                return 31
            }
            /* It's checking if the year is a leap year. */
            case 2: {
                /* It's checking if the current month is January. If it is, it's adding 1 to the current year. */
                let year = currentYear + (currentMonth > 1)
                if (!(year % 4) && year % 100)
                    return 29
                return 28
            }
            /* It's returning 30 if the month has 30 days. */
            default: { return 30 }
        }
    }), [today, months])

    /* It's creating an array of the number of days in each month. */
    const startDayOfMonth = useMemo(() => {
        let day = new Date()
        /* It's creating an array of the number of days in each month. */
        return months.map(a => {
            /* It's getting the month of the current date. */
            const month = a
            /* It's checking if the month is January. If it is, it's adding 1 to the current year. */
            const year = month >= 12 ? currentYear + 1 : currentYear
            /* It's setting the day to the first day of the month. */
            day.setFullYear(year, month, month >= 12 ? 0 : 1)
            /* It's getting the day of the week of the first day of the month. */
            let i = day.getDay() - 1
            /* It's getting the day of the week of the first day of the month. */
            return i < 0 ? 7 + i : i
        })
    }, [nbOfDay])

    /**
     * It takes a number as an argument, and returns a function that takes no arguments, and navigates
     * to the page that is the current page plus the number passed to the outer function
     */
    const navigation = i => () => {
        const requested = page + i;
        if (requested >= 0 && requested < amplitude - 1) {
            navigateTo(requested)
        }
    }

    /* It's setting the position of the calendar to the current page. */
    useEffect(() => {
        if (!mobile) document
            .querySelectorAll('.search-calendar > .search-calendar-box.noscrollbar > div')
            .forEach(element => element.style.transform = `translate( calc( ${page} * calc( -100% - 48px ) ) )`)
    }, [page])

    /* A React component that renders a calendar. */
    return (
        <div className={(mobile ? 'm-calendar ' : '') + "search-calendar " + (className || '')}>
            <div className='day-label'>
                {[1, 2, 3, 4, 5, 6, 7].map(a => <span key={a}>{dayNames[language][a]}</span>)}
            </div>
            {!mobile && <>
                <SingleArrow small orientation="left" onClick={navigation(-1)} className="calendar-arrow" />
                <SingleArrow small orientation="right" onClick={navigation(1)} className="calendar-arrow" />
            </>}
            <div className='search-calendar-box noscrollbar'>
                {months.map((_, monthNb) => {
                    const dayNb = nbOfDay[monthNb]
                    const delay = startDayOfMonth[monthNb]
                    const year = (monthNb + currentMonth) >= 12 ? currentYear + 1 : currentYear
                    return (
                        <MonthTile
                            language={language}
                            key={monthNames[language][monthNb]}
                            monthNb={monthNb}
                            currentMonth={currentMonth}
                            delay={delay}
                            dayNb={dayNb}
                            today={today}
                            onDateClick={onDateClick}
                            year={year}
                            dates={newPlage[0] ? [...dates, newPlage] : dates}
                        />
                    )
                })}
            </div>
            <div className='search-calendar-foot'>
                <BigButton type='light' onClick={() => skip()} text={texts[language].Passer} />
                <BigButton onClick={() => next(newPlage[0] ? [...dates, newPlage] : dates)} text={texts[language].Suivant} />
            </div>
        </div>
    )
}

/**
 * It renders a month, with the days of the month, and the days before and after the month, and the
 * days before and after the month, and the days before and after the month, and the days before and
 * after the month, and the days before and after the month, and the days before and after the month,
 * and the days before and after the month, and the days before and after the month, and the days
 * before and after the month, and the days before and after the month, and the days before and after
 * the month, and the days before and after the month, and the days before and after the month, and the
 * days before and after the month, and the days before and after the month, and the days before and
 * after the month, and the days before and after the month, and the days before and after the month,
 * and the days before and after the month, and the days before and after the month, and the days
 * before and after the month
 * @returns A div with a h3 and a div with 37 DayTile components
 */

const MonthTile = ({ monthNb, currentMonth, delay, dayNb, today, onDateClick, year, dates = [], language }) => {
    return <div>
        {/*Displaying the month and year of the current month. */}
        <h3>{monthNames[language][(monthNb + currentMonth) % 12]} {year}</h3>
        <div className='calendar-month'>
            {/* Creating a calendar. */
                new Array(37).fill(0).map((a, b) => {
                    /* Checking if the day number is less than or equal to the number of days in the month
                    minus the delay, or if the number of days in the month is greater than or equal to
                    the delay. */
                    if (dayNb <= (b - delay) ^ b >= delay) {
                        /* Declaring a variable called day and assigning it the value of b - delay + 1. */
                        const day = b - delay + 1
                        let passed = false
                        let type
                        /* Creating a new date object with the year, month, and day. */
                        let thisDate = new Date(Date.UTC(year, (monthNb + currentMonth) % 12, day))

                        /* Checking if the month is not set and the day is less than today's date. */
                        if (!monthNb && day < today.getDate())
                            passed = true

                        /* Comparing the current date to the dates in the array. */
                        switch (
                        dates.reduceRight((max, plage) => {
                            let a = compareToPlage(thisDate, plage)
                            return Math.min(Math.max(max, (a == 1 || a == 2) && (max == 1 || max == 2) ? max + a : a), 3)
                        }, -1)
                        ) {
                            /* A switch statement that is checking the value of the variable type. If the value is 0, then the type
                            variable is set to "all". */
                            case 0: { type = "all"; break }
                            /* A switch statement that is checking the value of the variable type. If the value is 1, it will set
                            the variable type to "out". If the value is 2, it will set the variable type to "in". If the value
                            is 3, it will set the variable type to "in". */
                            case 3: { type = "in"; break }
                            case 2: { type = "end"; break }
                            case 1: { type = "start"; break }
                            /* Checking the type of the file and setting the type variable to either "in" or "out" */
                            default: { type = "out"; break }
                        }
                        return <DayTile
                            key={b}
                            onClick={onDateClick(year, (monthNb + currentMonth) % 12, day)}
                            passed={passed}
                            type={type}
                            day={day}
                        />
                    }
                    return <div key={b}></div>
                })}
        </div>
    </div>
}
/**
 * 
 * @param {DayTileProps} param0 
 * @returns {JSXElement}
 */
const DayTile = ({ day, onClick, passed = false, type = "out" }) => {
    return <div onClick={passed ? () => { } : onClick} className={'calendar-tile ' + (passed ? "past" : type)}><div
    //fclassName={!passed && ["start", "end", "all"].includes(type) ? type : ""}
    >{day}</div></div>
}

export default CalendarSearch
/**
 * @typedef DayTileProps
 * @type {object}
 * @property {"out" | "in" | "start" | "end" | "all"} [type="out"]
 * @property {boolean} [passed=false]
 * @property {number} day
 * @property {?()=>void} onClick
 */

/**
 * Flow
 *
 * //1. Get current date
 * //2. Get current Month
 * //3. Start Calendar at Current Month
 * 4. Fade passed Date
 * 5. For Each date
 *  5.1 Find if in a plage
 *  5.2 Apply className in consequence
 */