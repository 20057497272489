import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './popup.scss'

const Popup = ({ children, open = false, close, className, closeCross, unique = true }) => {
    useEffect(() => {
        open && document.body.classList.add('block-scroll')
        return () => document.body.classList.remove('block-scroll')
    }, [open])
    useEffect(() => () => close(false), [])
    if (open !== true || !children) return <></>
    const Wrapped = () => <div className={"popup-overlay " + (className || '')} onClick={e => e.stopPropagation() || close(false)}>
        <div className={"popup-content " + (className || '')} onClick={e => e.stopPropagation()}>
            {closeCross && <a className="close circle" onClick={e => close(false)}>&times;</a>}
            {
                children instanceof React.Component || typeof children === 'function' ?
                    React.cloneElement(children, { close }) :
                    children
            }
        </div>
    </div>
    const container = document.querySelector('#popup-root')
    unique && container && ReactDOM.unmountComponentAtNode(container)
    return ReactDOM.createPortal(<Wrapped/>, container)
}

export default Popup