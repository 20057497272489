import Arrows from './Arrows'
import rubber from './Rubber'
import lazyImg from './LazyImg'
import bigbutton from './BigButton'
import StarRate from './StarRate'

/*
 *
 *
 *
 * 
 */


const UI = {
    Arrows,
    Rubber: rubber,
    LazyImg: lazyImg,
    StarRate,
    BigButton: bigbutton
}
export default UI
export * from './Arrows'
export const Rubber = rubber
export const LazyImg = lazyImg
export const BigButton = bigbutton
export * from './StarRate'