import React, { Suspense } from 'react'
import { Route } from "react-router-dom"

const STYLE = { height: "100vh", width: "100vw", zIndex: 1000, position: 'absolute', top: 0, left: 0, backgroundColor: 'white' }
const SuspenseRoute = props => {
  let {fallback, component : Component} = props
  let newProps = {...props}
  delete newProps.fallback
  delete newProps.component
  return <Route {...newProps}>
    <Suspense fallback={fallback || <div style={STYLE}></div>}>
      <Component {...newProps} />
    </Suspense>
  </Route>
}
export default SuspenseRoute