import axios from "axios";
import { APISAILS_URL } from "./apiUrl";
// import store from '../../store';
import * as CryptoJS from "crypto-js"
import standardSlugify from "standard-slugify";

let TOKEN = window.token;
let fetchingToken = false
export const getAuthToken = async () => {
  if (TOKEN) return TOKEN
  if (fetchingToken) return fetchingToken
  const obj = {
    appId: process.env.REACT_APP_API_KEY,
    password: process.env.REACT_APP_API_PASS,
    expiredDate: new Date(Date.now() + 7200000).toISOString(),
  }
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(obj), process.env.REACT_APP_ENCRYPT_SECRET).toString()
  const body = {
    appId: encodeURIComponent(encrypted),
    password: '35b7009ec0',
  };
  return fetchingToken = axios.post(APISAILS_URL + "/auth/login", body).then(a => TOKEN = a.data.token)
}

const _authorizationHeaders = () => {
  return ({
    Authorization: "Bearer " + TOKEN,
    "Content-Type": "application/json"
  })
};

export const validateToken = () => {
  return getAuthToken().then(() => get('/auth/validate_token')).then(res => {
    if (!res.success) throw new Error('Can\'t validate token')
    return res.user
  })
}

const handleError = err => {
  console.error(`Api call error in services -> request.js : `, err);
};

const formatEvent = event => {
  let pictureId = 0
  if (event.pictures && event.pictures[0] && event.pictures[0].id) {
    pictureId = event.pictures[0].id
  }
  console.info(event.inevitable)
  return {
    id: event.id,
    inevitable: event.inevitable,
    date: event.nextDate || event.date,
    datefin: event.nextDatefin || event.datefin,
    firstDate: event.date,
    lastDateFin: event.datefin,
    place: event.place,
    title: event.title,
    tags: (event.tags || []).map(e => e.name),
    picture: `${APISAILS_URL}/event/${event.id}/getImage/${standardSlugify(event.title, { keepCase: false })}.jpeg`,
    description: event.description,
    pictureId,
    origin: event.origin,
    site: event.site,
    url_ticketing: event.url_ticketing,
    url_game: event.url_game,
    price_order_id: event.price_order_id,
    // published:event.published, chatbot:event.chatbot, inevitable:event.inevitable, inevitableRate:event.inevitableRate, origin:event.origin,
    company: (event.company_events?.[0] || {}).company_id,
    address: event.coordinate.address,
    coordinate: {
      lat: event.coordinate.lat,
      lng: event.coordinate.lng
    },
    occurences: event.occurences.map(a => ({
      date: a.date,
      datefin: a.datefin,
      url: a.url_ticketing,
      id: a.id
    })),
    categories: event.categories ? event.categories.map(e => e.id) : undefined,
    categoriesName: event.categories ? event.categories.map(e => e.name) : undefined
  }
}

export const get = (url, params = null, headers = _authorizationHeaders()) => {
  return axios.get(APISAILS_URL + url, {
    headers: Object.assign({}, headers),
    params: Object.assign({}, params),
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    })
};

export const post = (url, data = {}, headers = _authorizationHeaders()) => {
  return axios({
    url: APISAILS_URL + url,
    method: "POST",
    headers: Object.assign({}, headers),
    data
  })
    .then(res => {
      return res.data.result ? res.data.result : res.data;

    })
    .catch(err => {
      return err;
    })
};

export const put = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  return axios({
    url: APISAILS_URL + url,
    method: "PUT",
    headers: Object.assign({}, headers),
    data
  })
    .then(res => {
      return res.data.result ? res.data.result : res;
    })
    .catch(err => {
      return err;
    })
};

export const patch = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: APISAILS_URL + url,
      method: "PATCH",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};

export const deleted = async (
  url,
  data = {},
  headers = _authorizationHeaders()
) => {
  try {
    const res = await axios({
      url: APISAILS_URL + url,
      method: "DELETE",
      headers: Object.assign({}, headers),
      body: JSON.stringify(data)
    });
    return res.data.result ? res.data.result : res.data;
  } catch (err) {
    handleError(err);
  }
};
const wrap = meth => (url, data, headers, raw) => getAuthToken().then(() => meth(url, data, headers, raw))

const ApisailsRequest = {
  get: wrap(get),
  post: wrap(post),
  put: wrap(put),
  deleted: wrap(deleted),
  patch: wrap(patch),
  formatEvent,
  getAuthToken
};
export default ApisailsRequest