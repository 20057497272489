import React, { useState, useEffect } from 'react'
import './locationSearch.scss'
import geolocation from '../../../helpers/geolocation'
import { UpperFirst } from '../../../helpers/utils.js'
import { compareLocation } from '../../../helpers/geolocation'
import { useTranslation } from 'react-i18next'
import { APISAILS_URL } from '../../../service/config/apiUrl'

/**
 * It renders a div with a title, subtitle, picture, and a checkmark if checked is true
 * @returns A div with a class of search-item and onClick event handler.
 */
const CheckedOption = ({ title, subtitle, picture, checked, onClick, className, imageClass, additionalLayers = [] }) => {
    return (
        <div key={title} className={"search-item " + (className || "")} onClick={onClick}>
            <div className={imageClass}>
                {picture && <img src={picture} className={imageClass} onError={e => (e.target.style.display = 'none')} />}
                {additionalLayers}
                {checked && <img className="checked" src="/img/checked.svg" />}
            </div>
            <h4>
                {title}
            </h4>
            <h6>{subtitle}</h6>
        </div>
    )
}

/**
 * It's a search bar that can be used to search for cities or categories
 * @returns A component
 */
const LocationSearch = ({
    type = true,
    input = "",
    next = console.log,
    items,
    continueAfterSpecial,
    checkedFilter,
    specialcheckedFilter
}) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    /* It's a destructuring assignment. It's the same as: */
    const [[checkedList, setCheckedList], [specialChecked, setSpecialChecked]] = [useState(checkedFilter), useState(specialcheckedFilter)]

    /**
     * If the type is true, then set the loading to true and return a timeout function that will run
     * the geolocation function, which will set the location and set the loading to false. If the type
     * is false, then set the specialChecked to true, set the checkedList to false, and run the next
     * function
     * @returns
     */
    const specialAction = () => {
        /* It's a destructuring assignment. It's the same as: */
        if (type) {
            setLoading(true)
            /* It's a destructuring assignment. It's the same as: */
            return setTimeout(() => geolocation(a => setLoc(a) || setLoading(false), () => setLoading(false)), 400)
        }
        /* It's a function that is passed as a prop to the component. It's a function that will be called when
        the user clicks on the "Surprise me" button. */
        next({ category: false, inevitable: true })
        setSpecialChecked(true)
        setCheckedList(false)
    }

    /* It's a destructuring assignment. It's the same as: */
    const [loc, setLoc] = useState(JSON.parse(sessionStorage.getItem('location')))

    /* It's a JSX expression. It's the same as: */
    return (
        <>
            <div className="search-mobile noscrollbar">
                <div className="top-search-box">
                    <CheckedOption
                        onClick={specialAction}
                        className={"top-search-item " + (type ? "city" : "category")}
                        additionalLayers={
                            <img src="/img/loading-process.svg"
                                alt="loading" className='geoloc-city-loading'
                                style={loading ? { transform: 'rotate(54000deg)', opacity: 1 } : {}} />
                        }
                        title={type ? t('searchBar.around-me') : t('searchBar.surprise-me')}
                        subtitle={type ? t('searchBar.geoloc') : ""}
                        imageClass={type ? "city" : "category"}
                    />
                </div>
                <h3 className="section-header">{type ? t('searchBar.most') : t('searchBar.most-cat')}</h3>
                <div className={"section-content" + (type ? " one-column" : "")}>
                    {console.log('ITEMS', items)}
                    {items
                        /* It's sorting the items by the distance from the user's location. */
                        .sort(
                            type && loc ?
                                (a, b) => compareLocation(a.coordinate, loc) - compareLocation(b.coordinate, loc) :
                                (a, b) => a.name.toLowerCase().indexOf(input.toLowerCase()) - b.name.toLowerCase().indexOf(input.toLowerCase())
                        )
                        .map(e => <CheckedOption
                            className={type ? "city" : "category"}
                            title={type ? UpperFirst(e.name) : t('categories.' + UpperFirst(e.name))}
                            subtitle={
                                type ?
                                    loc && "Vous êtes à " + Math.ceil(compareLocation(e.coordinate, loc) / 1000) + " km" :
                                    e.count && t('home.cities.count', { count: e.count })
                            }
                            picture={e.picture || `${APISAILS_URL}/category/${e.id}/getImage/${e.name}.jpg`}
                            onClick={() => {
                                type && e.name && localStorage.setItem('tnj_last_city_searched', e.name)
                                setCheckedList(type ? UpperFirst(e.name) : { name: UpperFirst(e.name), id: e.id })
                                setSpecialChecked(false)
                                type ? next(UpperFirst(e.name)) : next({ category: { name: UpperFirst(e.name), id: e.id }, inevitable: false })
                            }}
                        />)
                    }
                    {items.length === 0 && <span>{t('searchBar.noResult')}</span>}
                </div>

            </div>
        </>
    )
}

export default LocationSearch

