import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import LocationSearch from '../LocationSearch'
import CalendarSearch from '../CalendarSearch'
import { getAutocomplete, getCityFromCoordinates } from '../../../service/CategoryServices'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import useMedia from '../../../hooks/use-media'

import './searchView.scss'

const SearchView = ({ categories, cities }) => {
    let component = useRef(null)
    const history = useHistory()
    const { t, i18n } = useTranslation()
    const fromHistory = history.location.state || {}
    /**
     * Steps :
     * 0 => close
     * 1 => location filter
     * 2 => Category filter
     * 3 => Date filter
     */
    const [step, setStep] = useState(0)
    const [city, setCity] = useState(fromHistory.city)
    const [citiesComplete, setCities] = useState(cities)
    const [category, setCategory] = useState(fromHistory.category)
    const [dates, setDates] = useState(fromHistory.dates)
    const [input, setInput] = useState(fromHistory.input)
    const [key, setKey] = useState(0)
    const [inevitable, setInevitable] = useState(fromHistory.inevitable)

    useEffect(() => {
        document.body.classList[step ? 'add' : 'remove']('block-scroll')
        if (step ^ component.current?.classList?.contains('open')) openProcess(step)
        return () => document.body.classList.remove('block-scroll')
    }, [step])

    /* It's a function that takes in a value, checks if it's empty, if it's not empty, it calls the
    getAutocomplete
    function, and sets the cities state to the cities returned from the API. */
    const cityAutocomplete = useCallback(value => {
        /* It's a function that takes in a value, checks if it's empty, if it's not empty, it calls the
        getAutocomplete
        function, and sets the cities state to the cities returned from the API. */
        if (!value) return setCities(cities)
        /* It's a function that takes in a value, checks if it's empty, if it's not empty, it calls the
        getAutocomplete
        function, and sets the cities state to the cities returned from the API. */
        getAutocomplete(value)
            .then(res =>
                setCities(
                    res.data.reduce((prev, cur) => {
                        if (prev.find(a => a.name.toLowerCase() === cur.display_name.toLowerCase().split(',')[0])) return prev
                        const preSelectingCity = cities.find(a => a.name.toLowerCase() === cur.display_name.toLowerCase().split(',')[0])
                        return [...prev, (preSelectingCity || {
                            name: cur.display_name.split(',')[0],
                            picture: "",
                            coordinate: {
                                lat: parseInt(cur.lat),
                                lng: parseInt(cur.lon)
                            }
                        })]
                    }, cities.filter(a => a.name.length >= value && a.name.toLowerCase().startsWith(value.toLowerCase())))
                )
            )
    }, [cities])

    /**
     * It takes a value, checks if it's empty, if it's not empty, it calls the getCityFromCoordinates
     * function, and sets the city state to the city returned from the API
     * @returns The city name is being returned.
     */
    const cityFromCoordinates = value => {
        if (!value) return setCities(city)
        getCityFromCoordinates(value)
            .then(res => setCity(res.data.address.city))
    }

    /**
     * It takes a value, and depending on the step, it either sets the city, the category, or the dates
     * @returns The next function is being returned.
     */
    const next = value => {
        if (!step) return
        switch (step) {
            case 1: {
                typeof value === 'string' || !value.lat ? setCity(value) : cityFromCoordinates(value)
                break
            }
            case 2: {
                if (value.inevitable) {
                    setCategory("")
                    setInevitable(true)
                } else {
                    setCategory(value.category?.name)
                    setInevitable(null)
                }
                break
            }
            case 3: {
                setDates(value)
                const query = (inevitable ? '&inevitable=true' : category ? `&category=${category}` : "")
                    + (typeof city === 'string' ? `&city=${city}` : city ? `&coordinate=${city.lat}:${city.lng}` : "")
                    + (value && value[0] && value[0][0] ? `&date=${value[0][0].toISOString()}&datefin=${value[0][1].toISOString()}` : "")
                history.push('/evenements?' + query.slice(1), { city, category, dates: value, step, input })
            }
        }
        setStep(a => a + 1)
    }

    /* A memoized function that returns a component based on the step. */
    const FilterPage = useMemo(() => {
        switch (step) {
            case 1: return p => <LocationSearch {...p} items={citiesComplete.length ? citiesComplete : cities} mobile
                next={next} />
            case 2: return p => <LocationSearch {...p} input={undefined} items={categories} mobile type={false} next={next} />
            case 3: return p => <CalendarSearch {...p} language={i18n.language} next={next} skip={next} mobile />
            default: return () => <></>
        }
    }, [step, citiesComplete, cities])


    /**
     * It opens and closes the mobile menu
     * @returns A function that takes in a boolean value and returns a boolean value.
     */
    const openProcess = open => {
        if (component.current) {
            /* Selecting all the elements with the class `home-hero` and `home-top-bar-simple` */
            const hero = [...document.querySelectorAll('.home-hero, .home-top-bar-simple')]
            /* Selecting the image of the hero. */
            const img = document.querySelector('.home-hero-img')
            /* It adds or removes the class `openMenu` to the elements with the class `home-hero` and
            `home-top-bar-simple`. */
            hero.forEach(a => a.classList[open ? 'add' : 'remove']('openMenu'))
            /* It adds or removes the class `open` to the element with the class `home-search`. */
            component.current.classList[open ? 'add' : 'remove']('open')
            /* Setting the height of the component and the image to the height of the component and the
            image. */
            if (open)
                setTimeout(() => {
                    if (!component.current) return
                    component.current.style.height = getComputedStyle(component.current).height
                    img.style.height = getComputedStyle(img).height
                    document.querySelector('input.mobile')?.focus()
                }, 1000)
            /* Setting the height of the component and the image to the height of the component and the
                        image. */
            else {
                component.current.style.height = ''
                img.style.height = ''
            }
            /* It scrolls to the top of the page. */
            window.scrollTo({ top: 0 })
            return true
        }
        return false
    }

    /* Checking if the step is in the history. If it is, it opens the process and sets the step. */
    useEffect(() => {
        /* Checking if the step is in the history. If it is, it opens the process and sets the step. */
        if (fromHistory.step) {
            /* Opening the process and setting the step. */
            openProcess(true) && setStep(fromHistory.step)
            /* Removing the step from the history. */
            history.push(history.location.pathname + history.location.search, { ...history.location.state, step: undefined })
        }
    }, [])

    return (
        <div className="home-search" ref={component} key={key}>
            {!!step && <button type="button" className="back-arrow" onClick={() => step !== 1 ? setStep(step - 1) : openProcess(false) && setTimeout(() => setStep(step - 1), 800)}>
                <img src="/img/back-arrow.svg" alt="" />
            </button>}
            <div className="home-search-bar"
                onClick={() => !step && openProcess(true) && setStep(1)}
            >
                {step === 1 ?
                    <input
                        type='text'
                        className='mobile'
                        value={input || ""}
                        onChange={e => setInput(e.target.value)}
                        onKeyDown={e => (e.key === 'Enter' || (e.key === 'Backspace' && !e.target.value)) && cityAutocomplete(input)}
                        placeholder={city ? typeof city === 'string' ? city : t('search.input.autour') : t('search.input.ou')}
                    /> :
                    <span className='mobile'>
                        {step === 2 ?
                            category || t('search.input.quoi') :
                            dates && dates[0] instanceof Date ?
                                dates.map(a => a.toLocaleDateString()).join(' - ') :
                                step ?
                                    t('search.input.quand') :
                                    t('search.input.ou')}
                    </span>
                }

                <button type="button" className="loupe">
                    <img src="/img/loupe-green.svg" alt="" />
                </button>
            </div>
            <div className="home-search-lower">
                <FilterPage
                />
            </div>
        </div >)
}
export default SearchView