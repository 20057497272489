import SearchBar from './searchbar'
import main from './SearchMain'
/**
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */
export const SearchMain = main 
export default SearchBar
