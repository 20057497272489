import React from 'react'
import './lazy-img.scss'

const LazyImg = props => {
    let newProps = { ...props }
    newProps.className = (props.className || "") + ' lazy-img'
    const removeClass = e => e.nativeEvent.currentTarget.classList.remove('lazy-img')
    newProps.onLoad = removeClass
    newProps.onError = function (e) {
        removeClass(e);
        (props.onError || console.log)(e)
    }
    return (
        <img {...newProps} />
    )
}

export default LazyImg
