import React, { useState, useEffect } from "react"
import { getCategories, getCities } from '../../service/CategoryServices'
import { getUserInformation } from '../../service/UserServices/UserService'
import { useLocation, Link, useHistory } from 'react-router-dom'
import './topbar.scss'
import {
    BigButton
} from '../UI'
import { SearchMain } from '../SearchBar'
import { scrollTo } from '../../helpers/utils'
import { isLogin } from '../../helpers/utils'
import { useTranslation } from 'react-i18next';
import Login from '../Login'
import MenuMobile from '../MenuMobile'
import ReactDOM from 'react-dom'
import { useSelector } from "react-redux"

export const TopBar = ({ children, className, transparent = false, fixed = false, openLogin = false, openMenu }) => {
    const path = useLocation().pathname
    const history = useHistory()
    return (
        <header className={(className || '') + (transparent && ' transparent ' || '') + (fixed && ' fixed' || '')}>
            <MenuIcon openMenu={openMenu} />
            <img alt="timenjoy logo"
                src={!transparent ? "/img/logo.webp" : "/img/logo-dark.svg"}
                className={(className ? className + '-logo ' : '') + "tnj-logo"}
                onClick={() => path !== '/home' ? window.open('/home', '_self') : window.scrollTo({ top: 0, behavior: 'smooth' })}
            />
            <ProfileIcon transparent={transparent} openLogin={openLogin} />
            {children && React.cloneElement(children, { path, className: className ? className + '-landing-top' : '' })}
        </header>
    )
}

export const LandingTop = ({ className }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className='filler' >
                {/* <Link to="/covid-info">{t('covid.searchView')}</Link> */}
            </div>
            <nav className={(className ? className : '') + ' landing-top'} >
                <Link to="/" onClick={e => e.preventDefault() || scrollTo({ element: '.trending-cities' })}>
                    {t('menu.villes')}
                </Link>
                <Link to="/evenements">
                    {t('menu.evenements')}
                </Link>
                {/* <a href="https://mytimenjoy-dev.v3.timenjoy.fr" target='_blank'>
                    {t("menu.organiser")}
                </a> */}
            </nav>
            <JustButton className={(className ? className : '') + ' landing-top'} />
        </>
    )
}

const MenuIcon = ({ openMenu }) => {
    const [open, setOpen] = useState(openMenu)
    return <>
        <img className='mobile-icons' onClick={() => setOpen(true)} src='/img/menu-icon.svg' />
        <MenuMobile open={open} close={setOpen} />
    </>
}

const ProfileIcon = ({ transparent, openLogin }) => {
    const history = useHistory()
    const [popup, setPopup] = useState(openLogin)
    const user = useSelector(a => a.userData.user)
    return (
        <>
            <Login open={popup} close={setPopup} onComplete={() => history.push('/profil', getUserInformation())} />
            <img className='mobile-icons' src={transparent ? '/img/user-icon.svg' : '/img/user-icon-dark.svg'} onClick={() => user ? history.push('/profil', getUserInformation()) : setPopup(true)} />
        </>
    )
}
export const JustButton = ({ className, type, onClick, text }) => {
    const history = useHistory()
    const [popup, setPopup] = useState(false)
    const { t } = useTranslation()
    const user = useSelector(a => a.userData.user)

    return (
        <>
            <Login open={popup} close={setPopup} onComplete={login => login ? history.push('/profil', getUserInformation()) : getUserInformation()} />
            <BigButton type={type !== undefined ? type : "white"} text={user ? t("menu.profile") : text || t("menu.connexion")} className={className ? className : ''} onClick={() => user ? history.push('/profil', getUserInformation()) : setPopup(true)} />
        </>
    )
}
export const LandingTopWithSearch = ({ className, path }) => {
    const { t } = useTranslation()
    const history = useHistory()

    return (
        <>
            <div className='start-search'>
                <span className='input' onClick={
                    () => {
                        if (!["/", "/home"].includes(path)) return history.push('/', { openSearch: true })
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setTimeout(() => document.querySelector('.filters-box')?.click?.(), 400)
                    }} >{t('menu.search')}</span>
                <img src='/img/loupe-green.svg' />
            </div>
            <div className='filler' />
            <nav className={(className ? className : '') + ' landing-top with-search'} >
                {/* <Link to="/" onClick={e => e.preventDefault() || scrollTo({ element: '.trending-cities' })}>
                    {t('menu.villes')}
                </Link> */}
                <Link to="/" onClick={e => {
                    e.preventDefault()
                    if (path !== '/home') {
                        history.push('/home')
                        setTimeout(() => scrollTo({ element: '.trending-cities' }), 1000)
                    }
                    else scrollTo({ element: '.trending-cities' })
                }}>
                    {t('menu.villes')}
                </Link>
                <Link to="/evenements">
                    {t('menu.evenements')}
                </Link>
                {/* <a href="https://mytimenjoy-dev.v3.timenjoy.fr" target='_blank'>
                    {t("menu.organiser")}
                </a> */}
            </nav>
            <JustButton type='' className={(className ? className : '') + ' landing-top'} />
        </>
    )
}

export const FilterBar = ({ className, onChange, onFilterChange, defaultPlaceholders }) => {
    const [categories, setCategories] = useState([])
    const [cities, setCities] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()
    if (!defaultPlaceholders) defaultPlaceholders = [t('search.filter.ou'), t('search.filter.quoi'), t('search.filter.quand')]
    useEffect(() => {
        getCategories().then(res => {
            setCategories(res.rows)
        }).catch(console.error)
        getCities().then(res => {
            setCities([...res.featured, ...res.list])
        }).catch(console.error)
    }, []);

    const Darkener = () => ReactDOM.createPortal(<div style={{ height: '100vh', width: '100vw', position: 'fixed', zIndex: 49, backgroundColor: 'rgba(0,0,0,.25)', top: 0, left: 0 }} />, document.querySelector('#result-overlay'))
    return (
        <>
            {isOpen && <Darkener />}
            <div className='filler' />
            <div className="top-filter-bar-wrapper">
                <SearchMain
                    confirm
                    categories={categories}
                    cities={cities}
                    onFilterChange={onFilterChange}
                    className="top-filter-bar"
                    stopParent="top-filter-bar-wrapper"
                    defaultPlaceholders={defaultPlaceholders}
                    onOpen={bool => {
                        setIsOpen(bool)
                        document.body.classList[bool ? 'add' : 'remove']('block-scroll')
                        return () => { setIsOpen(false); document.body.classList.remove('block-scroll') }
                    }}
                />
            </div>
            <div className={(className || '') + ' top-filter-bar-user'}>
                <JustButton type='' />
                {/* <BigButton text={t('menu.create')} onClick={() => window.open("https://mytimenjoy-dev.v3.timenjoy.fr")} /> */}
            </div>
        </>
    )
}
export const ProfileBar = LandingTopWithSearch;
export default TopBar