import Apisails from '../service/config/ApisailsRequest'
import { resetLikedEvents } from '../store/events/actions'
import { resetActiveUser } from '../store/user/actions'
import store from '../store'
const TOKEN_KEY = 'jwt'; // which token use ?

export function isLogin() {
    return !!sessionStorage.getItem("token")
}

export function logIn(history) {
    sessionStorage.setItem(TOKEN_KEY, 'true')
    history.push('/')
}

export function logOut(history) {
    const params = {
        token: sessionStorage.getItem("token")
    }
    store.dispatch(resetActiveUser())
    store.dispatch(resetLikedEvents())
    Apisails.post("/user/logout", params)
    sessionStorage.removeItem("token")
    history.push('/')
}

// Clear localstorage when window is closed - USED IN APP.JS
// You may need to disable it if you want to keep session active
// after closing browser or refresh
export function clearLocal() {
    window.onbeforeunload = function () {
        //localStorage.removeItem(TOKEN_KEY);
        return '';
    }
}

export const scrollTo = ({ element, scroller = 'body', offset = 120, orTop = true, stopTo, behavior = 'smooth' }) => {
    try {
        const scTop = (typeof scroller === 'string' ? document.querySelector(scroller) : scroller)
        const top = (typeof element === 'string' ? document.querySelector(element) : element).getClientRects()[0].top - offset - scTop.getClientRects()[0].top
        let stop = Infinity

        if (top < 0) throw new Error('can\'t scroll above document : ' + top)

        if (stopTo) {
            stop = (typeof stopTo === 'string' ? document.querySelector(stopTo) : stopTo).getClientRects()[0].top
                + document.documentElement.scrollTop
                + scTop.scrollTop
                + scTop.getClientRects()[0].top
                - document.documentElement.getClientRects()[0].height
                - document.documentElement.getClientRects()[0].top
        }
        console.log('scroll', top, stop)
        return window.scrollTo({ top: Math.min(top, stop), behavior })
    } catch (err) {
        console.error(err)
        orTop && window.scrollTo({ top: 0, behavior: 'smooth' })
    }
}

/**
 * Passe la première lettre en majuscule et les autres en minuscule
 * @param {string} str 
 * @returns {string} la string formattée
 */
export const UpperFirst = str => typeof str === 'string' ? str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase() : ''

export const shuffle = arr => {
    let start = [...arr]
    let end = []
    new Array(start.length).fill(1).forEach(a => {
        let len = start.length
        if (len === 1) return end.push(start.shift())
        let rand = Math.floor(Math.random() * (len - 1))
        end.push(start[rand])
        start = start.slice(0, rand).concat(start.slice(rand + 1))
    })
    return end
}

export const copyEventListener = text => () => navigator.clipboard.writeText(text)
export const shareEventListener = opt => () => new Promise((resolve, reject) => navigator.share ? resolve(navigator.share(opt)) : reject("navigator.share not available"))