//import React from 'react';
    const test = process.env.REACT_APP_MODE !== 'production';
    
    
    //TODO interface ?
    const prod = {
        debug : test,
        APISAILS_URL: 'https://apisails.timenjoy.fr',
        WEBSITE_URL: 'https://timenjoy.fr',
        CRAWLOSAURE_API: 'https://crawlosaure.api.timenjoy.fr'
    };

    const devt = {
        debug : test,
        APISAILS_URL: 'https://apisails-dev.timenjoy.fr',
        WEBSITE_URL: 'https://timenjoy-dev.timenjoy.fr',
        CRAWLOSAURE_API: 'https://crawlosaure-dev.api.timenjoy.fr'
    };

    const local = {
        debug : test,
        APISAILS_URL: 'http://localhost:1338',
        WEBSITE_URL: 'http://localhost:4300',
        CRAWLOSAURE_API: 'http://localhost:8787'
    };

    const config = (process.env.REACT_APP_MODE === 'local') ? local : ((process.env.REACT_APP_MODE === 'development') ? devt : prod);
    // const config = (process.env.NODE_ENV === 'development') ? devt : ((process.env.NODE_ENV === 'local') ? local : prod);

export default config;
