import GTM from 'react-gtm-module'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { validateToken } from '../config/ApisailsRequest'

export const initialize = location => {
    GTM.initialize({ gtmId: process.env.REACT_APP_GTM_ID })
    if (!isInitialized()) {
        const queryParams = (location || window.location).search.slice(1).split('&').reduce((prev, cur) => {
            let [a, b] = cur.split('=')
            prev[a] = b
            return prev
        }, {})
        // validateToken().then(user => {
        //     console.log('VALIDATE',user)
        GTM.dataLayer({
            dataLayer: {
                event: 'generic',
                app_id: queryParams.appId || '',
                company_name: queryParams.utm_source || '',
                environment: process.env.REACT_APP_MODE,
                eventName: queryParams.utm_campaign || '',
                pageType: ((location || window.location).pathname.slice(1) || 'home').split('/')[0]
            }
        })
        console.info('GTM initializing first page', location || window.location)
        sessionStorage.setItem('gtm', 'initialized')
        // })
    }
    trackPageViews()
}
export const isInitialized = () => sessionStorage.getItem('gtm') === 'initialized'

export const GTMEnabled = () => isInitialized() && !localStorage.getItem('gtm_blocked')

export const dataLayer = data => GTMEnabled() && GTM.dataLayer({ dataLayer: data })

export const trackPageViews = () => {
    console.info("TRACK PAGE VIEW", window.location.pathname)
    dataLayer({
        event: 'pageview',
        'page Path': window.location.pathname
    })
}

export const trackSearchEvent = ({ count = '', location = '', date = '', category = '' }) => {
    dataLayer({
        event: 'eventRecherche',
        searchType: count > 0 ? 'Recherche avec résultats' : 'recherche sans résultats',
        location,
        date,
        category
    })
}

export const trackClickBook = ({ incontournable, place, city, categories, tags, eventName, eventID }) => {
    dataLayer({
        event: 'clickbook',
        clickType: 'Reserver',
        eventLocation: city,
        eventCategory: categories,
        eventTag: tags,
        eventPrice: '',
        eventName,
        eventID,
        eventIncontournable: incontournable,
        eventPlace: place
    })
}

export const trackClickBanner = ({ incontournable, place, bannerName, url, city, categories, tags, eventName, eventID }) => {
    dataLayer({
        event: 'clickbanner',
        clickType: 'affiliatebanner',
        bannerName: bannerName,
        bannerPath: url,
        eventLocation: city,
        eventCategory: categories,
        eventTag: tags,
        eventPrice: '',
        eventName,
        eventID,
        eventIncontournable: incontournable,
        eventPlace: place
    })
}
export const trackClickCreateEvent = () => {
    dataLayer({
        event: 'clickCreateEvent',
        clickType: 'Créer mon événement',
        eventLocation: '',
        eventCategory: '',
        eventTag: '',
        eventPrice: ''
    })
}
export const trackClickPromoteEvent = () => {
    dataLayer({
        event: 'clickPromoteEvent',
        clickType: 'Promouvoir mon événement',
        eventLocation: '',
        eventCategory: '',
        eventTag: '',
        eventPrice: ''
    })
}

export const trackLogSign = type => ({ incontournable = '', place = '', city = '', categories = '', tags = '', eventName = '', eventID = '' }) => {
    dataLayer({
        event: type,
        clickType: type,
        'pagePath': window.location.pathname,
        eventLocation: city,
        eventCategory: categories,
        eventTag: tags,
        eventPrice: '',
        eventName,
        eventID,
        eventIncontournable: incontournable,
        eventPlace: place
    })
}
const GTMObserver = () => {
    const history = useHistory()
    useEffect(() => history.listen(trackPageViews), [])
    return <></>
}

export default GTMObserver