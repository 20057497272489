import React from 'react'
import './bigbutton.scss'

const BigButton = ({
    text,
    children,
    className = "",
    type = "",
    id,
    style,
    onClick = a => { a.stopPropagation(); console.log(a) }
}) => <button style={style} type="button" {...id ? { id } : {}} className={className + " big-button " + type} onClick={onClick}>{children || text}</button>

export default BigButton