import Apisails from '../config/ApisailsRequest'

export const addUpdateContact = (user) => {
  return new Promise((resolve, reject) => {
    updateContact(user)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        return addContact(user);
      })
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error)
        // return { info: "add", err: error };
      })
      .then(() => {
        return addToList(user)
      })
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
  });
  // addContact(user)
  // .catch(error => {
  //     console.log("ADD conctact", error)
  //     return updateContact(user).catch(error => {
  //         return { info: "add", err: error }
  //     })
  // })
}

function getListId(postal) {
  let codes = [[67, 73], [72, 80], [71, 21], [70, 20], [69, 44], [68, 34], [67, 30], [66, 84], [65, 59], [64, 33], [63, 83], [62, 69], [60, [75, 91, 92, 93, 94, 95, 77, 78]], [50, 6], [27, 13], [89, 31], [90, 63], [91, 63], [92, 51], [93, 66], [94, 38], [95, 74], [96, 35], [97, 76]]
  let test = codes.filter(x => {
    if (Array.isArray(x[1]) && x[1].filter(x => x == postal).length > 0) {
      return x[1].filter(x => x == postal)
    }
    else {
      return x[1] == postal
    }
  })
  return test[0][0]
}

async function updateContact(user) {
  return new Promise(async (resolve, reject) => {
    const data = {
      user: user.listId ? { email: user.email } : user,
      listId: 80
    };
    Apisails
      .put("/sendinblue/contacts", data)
      .then(response => {
        if (response.isAxiosError) {
          reject(response)
        } else {
          resolve(response);
        }
      })
      .catch(error => {
        reject(error.message || error);
      });
  })
}

async function addToList(user) {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'api-key': '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'
    },
    body: JSON.stringify({ emails: [user.email] })
  };
  [getListId(user.zip_code.slice(0, 2)), 49].map(x => {
    return fetch(`https://api.sendinblue.com/v3/contacts/lists/${x}/contacts/add`, options)
      .then(response => response.json())
      .then(response => console.log(response))
      .catch(err => console.error(err));
  })
}

export async function addContact(user) {
  return new Promise((resolve, reject) => {
    const data = {
      user: user.listId ? { email: user.email } : user,
      listId: 80
    };
    Apisails
      .post("/sendinblue/contacts", data)
      .then(response => {
        if (response.isAxiosError) {
          reject(response)
        } else {
          resolve(response);
        }
      })
      .catch(error => {
        reject(error.message || error);
      });
  })
}

export const sendEmailTemplate = (email, templateId) => {

  const data = {
    emailTo: email,
    templateId: templateId
  }
  Apisails
    .post("/sendinblue/sendEmailTemplate", data)
    .then(response => { console.log(response); return response })
    .catch(error => { console.log(error); return (error.message || error) });
}

export function updateBlackListedStatus(id, status) {
  let promise = new Promise((resolve, reject) => {
    let body = {
      value: status
    }
    Apisails
      .put("/user/" + id + "/setBlackList", body)
      .then(response => resolve(response))
      .catch(error => reject(error.message || error))
  })
}

export async function isBlacklisted(id) {
  return Apisails.get("/user/" + id + "/isblacklisted")
    .then(data => {
      if (data.emailBlacklisted) {
        return true
      } else {
        return false
      }
    })
    .catch(error => { console.log(error); return (error.message || error) });
}
const SendingblueService = {
  sendEmailTemplate,
  addUpdateContact,
  updateContact,
  addContact,
  updateBlackListedStatus,
  isBlacklisted
}

export default SendingblueService
