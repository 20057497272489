import React, { useState, useEffect } from 'react'
import { getCategories, getCities } from '../../service/CategoryServices'
import './searchbar.scss'
import Media from 'react-media';
import SearchMain from './SearchMain'
import SearchView from './SearchView'
import { getSailsEventsCount } from '../../service/EventService'

const PutainCétaitChaudAFaire = (props) => {

    const [categories, setCategories] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        getCategories()
            .then(res => {
                setCategories(res.rows)
                if(res.rows[0].count) return false
                return res.rows.map(a => a.id)
            })
            .then(cats => {
                if(!cats) return
                cats.forEach(cat => getSailsEventsCount(undefined, [cat])
                    .then(({ metaData: { count } }) => setCategories(a => {
                        (a[a.findIndex(b => b.id === cat)] || {}).count = count
                        return a
                    }))
                    .catch(console.error))
            })
            .catch(console.error)
        getCities()
            .then(res => setCities([...res.featured, ...res.list]))
            .catch(console.error)
    }, [true]);

    return (
        <Media queries={{
            mobile: '(max-width: 450px)',
            // small: '(max-width: 780px), (max-height: 640px)',
            // large: '(min-width: 781px)  and (min-height: 641px)'
        }}>
            {
                ({ mobile }) => mobile ?
                    <SearchView  {...props} categories={categories} cities={cities} /> :
                    <div className="home-search">
                        <SearchMain {...props} popupFollowStep categories={categories} cities={cities} />
                    </div >
            }
        </Media>
    )
}

export default PutainCétaitChaudAFaire
// export default SearchBar
