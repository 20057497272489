const setLikedEvents = data => ({
  type: "SET_LIKED_EVENTS",
  data
});

const removeLikedEvents = data => ({
  type: "REMOVE_LIKED_EVENTS",
  data
});

const addLikedEvent = data => ({
  type: "ADD_LIKED_EVENT",
  data
})

const resetLikedEvents = data => ({
  type: "RESET_LIKED_EVENTS",
});

export const mutation = {
  setLikedEvents,
  resetLikedEvents,
  removeLikedEvents,
  addLikedEvent
};
  