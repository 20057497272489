import { Arrows } from './arrows'

/*
 * ARROWS
 *
 * Composant qui affiche deux fleches cliquables. Composé de deux <SingleArrow /> sans la props 'small'
 * 
 * Reçois en props les fonctions onPrevious et onNext, appelées lors des appuis sur les fleches et les
 * variables previous et next definissant si le bouton correspondant est activé (true) ou non (false)
 * 
 * SINGLE ARROW
 * 
 * Reçois en props la valeur de l'attribut 'disabled' du bouton, le format small (booléen),
 * l'orientation ( "left" ou "right" ) et la fontion onClick 
 * 
 */

export default Arrows

export * from './arrows'