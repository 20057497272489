import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import EN from './en.json'
import FR from './fr.json'


const resources = {
  en: { translation: EN },
  fr: { translation: FR }
};

i18n
  // load translation using http -> learn more: https://github.com/i18next/i18next-http-backend
  //   .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // for all options read: https://www.i18next.com/overview/configuration-options
    fallbackLng: 'fr',
    resources,
    detection: {
      order: ['querystring', 'localStorage'],
      lookupLocalStorage: 'i18nextLng',
      lookupQuerystring: 'lang',
      caches: ['localStorage'],
    },
    debug: false,
    keySeparator: ".",
    supportedLngs: ['fr', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;