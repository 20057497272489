import React, { useState, useRef } from "react"
import { withRouter } from 'react-router-dom'
import { UpperFirst } from '../../helpers/utils.js'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { addUpdateContact } from '../../service/Sendingblue/Sendingblue'
import './footer.scss'

const cities = ["Nice",
    "Marseille",
    "Paris",
    "Lyon",
    "Toulouse",
    "Bordeaux",
    "Strasbourg"
]
const categories = [
    { name: "Festival", id: "fad90ef7-f767-4056-b30b-39f678ef33e9" },
    { name: "Sport", id: "aa171186-6e0e-49a4-ae1f-e0298e675a55" },
    { name: "Tourisme", id: "705ca701-8104-4f04-a484-ad16d666c071" },
    { name: "Vie nocturne", id: "2e564bc1-ca68-435d-b3c2-1580b602b872" },
    { name: "Exposition", id: "cc024d4f-f7f0-4f2e-ae5e-287a3d6b9046" },
    { name: "Théâtre", id: "87e69edc-d090-4dde-9f01-43c199de1bd4" },
    { name: "Live Event", id: process.env.REACT_APP_MODE === "development" ? "15463e4a-a8c3-4c84-b2f4-c4b46a3d2577" : "f7170a17-c24a-456b-9047-79684ab97ea6" }
]


/**
 * It renders a footer with a list of cities, categories and links to the different pages of the
 * website
 * @returns A footer component
 */
const Footer = ({ history, setFilters = console.log, className }) => {
    // const [categories, setCategories] = useState([])
    const { t, i18n } = useTranslation()
    const [email, setEmail] = useState()
    const logSpan = useRef(null)
    /**
     * It takes a text string and a boolean, and if the boolean is true, it adds the class 'failed' to
     * the logSpan element
     * @param text - The text to display in the log
     * @param [failed=false] - boolean - whether the message is a failure or not
     * @returns The function logMessage is being returned.
     */
    const logMessage = (text, failed = false) => {
        if (!logSpan.current) return
        logSpan.current.innerHTML = text
        logSpan.current.classList[failed ? 'add' : 'remove']('failed')
    }

    /**
     * It takes an email address, checks if it's valid, then sends it to the Mailjet API
     * @returns 
     */
    const registerNewsLetter = () => {
        /* It's a regex that checks if the email is valid. */
        const test = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
        /* It's checking if the email is valid. If it's not, it displays an error message. */
        if (!test) return logMessage("Veuillez renseigner une adresse email valide", true)
        logMessage("")
        /* It's sending the email to the Mailjet API. */
        addUpdateContact({ email, listId: 81 }).then(({ status }) => {
            /* It's checking the status of the response from the Mailjet API. If the status is 201, it
            means that the email has been added to the list. If the status is 204, it means that the
            email was already in the list. If the status is neither 201 nor 204, it means that an
            error occured. */
            switch (status) {
                case 201: {
                    logMessage("Félicitation, vous êtes inscrit à notre newsletter.");
                    setTimeout(() => logMessage(""), 4000)
                    setEmail()
                    return
                }
                case 204: {
                    logMessage("Vous êtes inscrit à notre newsletter.");
                    setTimeout(() => logMessage(""), 4000)
                    return
                }
                default: {
                    logMessage("Une erreur s'est produite. Veuillez réessayer", true);
                    setTimeout(() => logMessage(""), 2000)
                    return
                }
            }
        })
    }

    /* A React component that renders the footer of the website. */
    return (<footer className={className}>
        <div>
            <div className='panel-box'>
                <div className="panel">
                    <h5>{t('footer.villes')}</h5>
                    <ul>
                        {cities.slice(0, 7).map((name, i) => <li key={name} onClick={() => {
                            setFilters({ city: name })
                            history.push('/evenements?city=' + name);
                        }}>{UpperFirst(name)}</li>)}
                    </ul>
                </div>
                <div className="panel">
                    <h5>{t('footer.categories')}</h5>
                    <ul>
                        {categories.slice(0, 7).map(({ name, id }) => <li key={id} onClick={() => {
                            setFilters({ category: { name, id } })
                            history.push('/evenements?category=' + name.toLowerCase())
                        }}>{t('categories.' + name)}</li>)}
                    </ul>
                </div>
                <div className="panel">
                    <h5>{t('footer.communaute')}</h5>
                    <ul>
                        <li onClick={() => history.push('/profil')}>{t('footer.register')}</li>
                        <li onClick={() => window.open('https://www.facebook.com/timenjoyapp/?ref=bookmarks')}>{t('footer.SN')}</li>
                        <li onClick={() => window.open('https://www.blog.timenjoy.fr/')}>Blog</li>
                        <li><a href='mailto:support-client@timenjoy.fr'>{t('footer.contact')}</a></li>
                        <li onClick={() => history.push('/terms/cgu')}>CGU</li>
                        <li onClick={() => history.push('/terms')}>{t('footer.legal-info')}</li>
                        <li onClick={() => window.open('https://mytimenjoy.timenjoy.fr/')}>Timenjoy Business</li>
                    </ul>
                </div>
            </div>
            <div className="grid-container">
                <div className="newsletter">
                    <h4>{t('footer.newsletter.title')}</h4>
                    <h6>{t('footer.newsletter.subtitle')}</h6>
                    <span className="newsletter-log-message" ref={logSpan} />
                    <div>
                        <input type="text" value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder={t('footer.newsletterPlaceholder')}
                            onKeyDown={e => e.key === 'Enter' && registerNewsLetter()} />
                        <span className="button" onClick={registerNewsLetter} type="submit">{t('footer.newsletterSubmit')}</span>
                    </div>
                </div>
                <div className="social-network">
                    <div>
                        <img src="/img/facebook.webp" onClick={() => window.open('https://www.facebook.com/timenjoyapp/?ref=bookmarks')} />
                    </div>
                    <div>
                        <img src="/img/twitter.webp" onClick={() => window.open('https://twitter.com/timenjoy?lang=fr')} />
                    </div>
                    <div>
                        <img src="/img/instagram.webp" onClick={() => window.open('https://www.instagram.com/time_n_joy/')} />
                    </div>
                    <div>
                        <img src="/img/linkedin.webp" onClick={() => window.open('https://fr.linkedin.com/company/time-n%27%E2%80%8B-joy')} />
                    </div>
                </div>

            </div>
        </div>
        <p>
            <span className='language-switch'>
                <span onClick={() => i18n.changeLanguage('fr')} className={i18n.language === 'fr' ? 'active' : ''}>Français</span>
                <span onClick={() => i18n.changeLanguage('en')} className={i18n.language === 'en' ? 'active' : ''}>English</span>
            </span>
            <span>{t('footer.legal')} - <Link to='/terms/cgu'>{t("footer.terms-of-use")}</Link> / <Link to='/terms'>{t('footer.legal-info')}</Link></span>
        </p>
    </footer>)
}

export default withRouter(Footer)
