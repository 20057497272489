const setActiveUser = data => ({
  type: "SET_ACTIVE_USER",
  data
});

const resetActiveUser = () => ({
  type: "RESET_USER_DATA",
});

export const mutation = {
  setActiveUser,
  resetActiveUser
};
  