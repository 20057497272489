import React, { useCallback } from 'react'
import './arrows.scss'
import { ReactComponent as SVG } from '../../../assets/arrow.svg'

export const SingleArrow = ({
    onClick = e => e.preventDefault() || e.stopPropagation(),
    disabled = false,
    orientation = "right",
    small = false
}) => {
    // const noAction = e => { console.log(e); e.preventDefault(); e.stopPropagation() }
    return (
        <div className={"arrow "+orientation} onClick={onClick}>
            <SVG className={orientation + (small ? " small" : "") + (disabled ? " disabled" : "")} />
        </div>
    )
}

export const Arrows = ({ previous = true, onPrevious, next = false, onNext }) => {
    return (
        <div className="arrows-component">
            <SingleArrow orientation="left" disabled={!previous} onClick={onPrevious} />
            <SingleArrow orientation="right" disabled={!next} onClick={onNext} />
        </div>
    )
}