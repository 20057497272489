import axios from 'axios';
import Apisails from '../config/ApisailsRequest'
import { APISAILS_URL } from "../config/apiUrl"
import SendingBlue from '../Sendingblue/Sendingblue'
import { setLikedEvents } from '../../store/events/actions'
import { setActiveUser } from '../../store/user/actions'
import { getFavoritesEvents } from '../EventService/index'
import store from '../../store'
import { getCityFromCoordinates } from '../CategoryServices';

export const loginUser = ({ mail, password, remenberMe }) => {
    return Apisails.post("/user/login", { mail, password }).then(
        async res => {
            if (!res.response) {
                delete res.user.password
                const likedEvents = await getFavoritesEvents(res.user.id, 1, 100)
                store.dispatch(setLikedEvents(likedEvents));
                store.dispatch(setActiveUser(res.user));
                if (remenberMe) {
                    localStorage.setItem("token", res.token);
                } else {
                    sessionStorage.setItem("token", res.token);
                }
                localStorage.setItem("user", JSON.stringify(res.user));
            }
            return res
        }
    )
}
export const testEmail = mail => {
    return Apisails.post("/user/login", { mail, password: '*' })
}
const setUser = (data) => {
    localStorage.setItem("user", data);
}

export const getUserInformation = () => {
    return JSON.parse(localStorage.getItem("user"));
}

const getUserSession = () => {
    return JSON.parse(localStorage.getItem("user"));
}

export const getUserId = () => {
    return JSON.parse(localStorage.getItem("user")).id;
}

export async function updateUser(data) {
    console.log("DATA", data)
    data.city = await getCityFromCode(data.zip_code)
    const user = Object.assign({}, data)
    delete user.mail;
    delete user.email;
    // const id = getUserId();
    console.log("USER", user)
    return Apisails
        .put("/user/" + data.id, user)
        .then(
            async res => {
                const blacklisted = await SendingBlue.isBlacklisted(data.id)
                console.log("BLACKLISTED1", blacklisted)
                console.log(data.blacklisted)
                if (!data.blacklisted && data.email) {
                    console.log("BLACKLISTED2", blacklisted)
                    if (!blacklisted) {
                        console.log("BLACKLISTED3", blacklisted)
                        SendingBlue.updateBlackListedStatus(data.id, true)
                    }
                    data.email ? SendingBlue.addUpdateContact(data) : console.log("No email")
                }
                else {
                    console.log("BLACKLISTED4", blacklisted)
                    if (blacklisted) {
                        console.log("BLACKLISTED5", blacklisted)
                        SendingBlue.updateBlackListedStatus(data.id, false)
                    }
                    else {
                        SendingBlue.updateBlackListedStatus(data.id, false)
                    }
                }
                data.email ? store.dispatch(setActiveUser(data)) : console.log("No email")
                data.email ? setUser(JSON.stringify(data)) : console.log('No email')
                data.email ? localStorage.setItem("user", JSON.stringify(res)) : console.log('No email')
                return res
            },
            err => {
                return err
            }
        )
}

export const updatePassword = (data) => {
    // const id = JSON.parse(localStorage.getItem("user")).id
    return Apisails.put("/user/" + data.id + "/changepassword", data)
}

export async function getCityFromCode(code) {
    try {
        const response = await axios.get("https://geo.api.gouv.fr/communes?codePostal=" + code)
        if (response.data[0].nom === undefined) {
            console.log("RESPOOOOOONSE", response)
            return ""
        }
        else {
            console.log("responseee", response)
            return response.data[0].nom
        }
    }
    catch (err) {
        console.log(err)
        return ""
    }
}

export async function registerUser(data) {
    console.log("REGISTER", data)
    const cguDate = new Date().toISOString().slice(0, 10) + 'Z'
    const templateId = 333
    const city = await getCityFromCode(data.zip_code)
    data.city = city
    console.log(city)

    return Apisails.post("/user/register", data)
        .then(res => {
            console.log(res)
            if (res.response && res.response.status !== 200) return res
            store.dispatch(setActiveUser(data))
            if (data.newsletter) {
                SendingBlue.addUpdateContact(res)
                SendingBlue.sendEmailTemplate(data.email, templateId)
            }
            else {
                SendingBlue.addUpdateContact(res).then(() => SendingBlue.updateBlackListedStatus(res.id, true))
                SendingBlue.updateBlackListedStatus(res.id, true)
                SendingBlue.sendEmailTemplate(data.email, templateId)
            }
            localStorage.setItem("user", JSON.stringify(res));
            return res
        })
        .then(res => {
            if (res.response && res.response.status !== 200) return res
            const update = {
                id: res.id,
                city: city,
                zip_code: data.zip_code,
                cguDate
            }
            sessionStorage.setItem("token", res.token);
            localStorage.setItem("user", JSON.stringify(res));
            return updateUser(update)
        })
}

export const forgotPassword = (email) => {
    console.log(email)
    return Apisails.post("/user/passwordlost", email)
}

export const getSessionInfo = (sessionId) => {
    return Apisails.get("/usersession/" + sessionId);
}

export const ResetPassword = (data) => {
    const pass = Object.assign({}, data)
    delete pass.id
    console.log("Data", data)
    return Apisails.put("/user/" + data.id + "/resetpassword", pass)
}

export const facebookLogin = data => {
    const body = {
      // firstname: data.name?.split?.(' ')[0],
      // lastname: data.name?.split?.(' ')[1],
        firstname: data.first_name,
        lastname: data.last_name,
        mail: data.email,
        accessToken: data.accessToken,
        // urlPicture: data.picture?.data?.url,
        urlPicture: data.picture?.data?.url,
        facebookId: data.id
    }
    return Apisails.post("/user/loginFacebook", body)
        .then(async res => {
            const likedEvents = await getFavoritesEvents(res.user.id, 1, 100)
            store.dispatch(setLikedEvents(likedEvents));
            store.dispatch(setActiveUser(res.user));
            (data.remenberMe ? localStorage : sessionStorage).setItem('token', res.token)
            localStorage.setItem("user", JSON.stringify(res.user));
            return res
        })
}

const UserService = {
    loginUser,
    registerUser,
    forgotPassword,
    getUserInformation,
    updateUser,
    updatePassword,
    getUserId,
    getSessionInfo,
    facebookLogin
}

export default UserService
