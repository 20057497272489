import React, { useRef, useMemo, useState, forwardRef } from 'react'
import './search-input.scss'
import { mergeRefs } from '../../../../helpers/customHooks'

const SearchInput = forwardRef(({ className, onChange, showControl, defaultValue, placeholder, onClick, onTimedChange }, ref) => {
    const [value, setValue] = useState(defaultValue)
    const [focus, setFocus] = useState(false)
    const inputRef = useRef(null)
    const timedChange = useMemo(() => {
        const a = (function* gen() {
            let i = 0;
            let lastChange
            while (true) {
                let newValue = yield i
                if (newValue !== null) {
                    i += 1
                    lastChange = newValue
                } else {
                    if (i === 1) {
                        onTimedChange?.(lastChange)
                    }
                    i--
                }
            }
        })()
        a.next()
        return a
    }, [onTimedChange])
    const sureChange = ({ target: { value: newValue } }) => {
        setValue(newValue)
        if (onTimedChange) {
            timedChange.next(newValue)
            setTimeout(() => timedChange.next(null), 300)
        }
        onChange?.(newValue)
    }
    return (
        <div className={'search-input' + (className || '')} onClick={e => e.stopPropagation()}>
            <input
                onChange={sureChange}
                value={value || ''}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                className={'input ' + (className || '')}
                type="text"
                onClick={onClick}
                ref={mergeRefs(ref, inputRef)}
                placeholder={focus ? "" : placeholder} />
            {value && showControl && <img
                src="/img/cross-dark.svg"
                alt='' onClick={e => {
                    e.target = inputRef.current
                    onClick?.(e)
                    console.log(inputRef.current.focus())
                    setValue()
                    onChange?.()
                    onTimedChange?.()
                }} />
            }
        </div>
    )
})

export default SearchInput