function componentLoader(lazyComponent, attemptsLeft = 10) {
    return new Promise((resolve, reject) => {
      lazyComponent()
        .then(resolve)
        .catch((error) => {
          // let us retry after 1500 ms
          setTimeout(() => {
            if (attemptsLeft === 1) {
              reject(error)
              return
            }
            componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
          }, 300);
        });
    });
  }

export default componentLoader