import React, { useMemo, useState, useRef, useEffect } from 'react'
import Popup from '../Wrappers/Popup'
import BigButton from '../UI/BigButton'
import { PasswordInput } from '../UI/Inputs'
import { useTranslation } from 'react-i18next'
import './login.scss'
import { loginUser, registerUser, forgotPassword, testEmail, getSessionInfo, facebookLogin } from '../../service/UserServices/UserService'
import { useHistory } from 'react-router'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { trackLogSign } from '../../service/AnalyticService'
import config from "../../service/config/config"

/**
 * It's a popup that allows the user to log in, register, or reset their password
 * @returns A React component
 */
const Loginv2 = ({ open, close, onComplete = console.log }) => {

    const { t, i18n } = useTranslation()
    const [step, setStep] = useState("connexion")
    const [registerInfo, setRegisterInfo] = useState({})
    const topLeft = useRef(null)
    const history = useHistory()

    const [reset, setReset] = useState()

    /* A hook that is called when the component is mounted and when the `open` prop changes. */
    useEffect(() => {
        if (!open) {
            setStep("connexion")
            setRegisterInfo({})
        }
    }, [open])



    /* A React hook that returns a function that returns a JSX element. */
    const Form = useMemo(
        () => {
            /* A switch statement that returns a function that returns a JSX element. */
            switch (step) {
                case "connexion": return () => (<>
                    <div className='input'>
                        <input type='text' name="mail" placeholder={t('user.email')} />
                    </div>
                    <PasswordInput className='input' name="password" placeholder={t('password.password')} />
                    <span className='forgot' onClick={() => setStep("reset")}>{t('connexion.forgot')}</span>
                </>)

                case "inscription": return () => (<>
                    <div className='input'>
                        <input type='text' name="email" placeholder={t('user.email')} autocomplete={'new-password'} />
                    </div>
                    <PasswordInput className='input' name="password" placeholder={t('password.password')} autocomplete={'new-password'} />
                    <PasswordInput className='input' name="password_check" placeholder={t('password.check-password')} autocomplete={'new-password'} />
                    <span className='checkboxSpan'>
                        <input type="checkbox" name="cgu" id='cguCheckBox' /> <label>{t('popup.agree-cgu')} <a className="link" target='_blank' href={config.WEBSITE_URL + '/terms/cgu'}>{t('popup.terms-of-use')}</a></label>
                    </span>
                    <span className='checkboxSpan'>
                        <input type="checkbox" name="newsletter" id='newsCheckBox' /> <label>{t('popup.newsletter')}</label>
                    </span>
                </>)

                case "inscription-2": return () => (<>
                    <div className='db-input'>
                        <input type='text' name="firstname" placeholder={t('user.firstname')} />
                        <input type='text' name="lastname" placeholder={t('user.lastname')} />
                    </div>
                    <div className='input'>
                        <input type='text' name="zip_code" placeholder={t('user.zip')} />
                    </div>
                </>)
                case "reset": return () => (<>
                    <div className='input'>
                        <input type='text' name="email" placeholder={t('user.email')} autocomplete={'new-password'} />
                    </div>
                </>)
                default: return () => <></>
            }
        },
        [step, i18n.language],
    )

    /**
     * `logMessgae` is a function that takes a string as an argument and logs it to the top left corner
     * of the screen
     */
    const logMessgae = text => {
        if (topLeft.current) topLeft.current.innerHTML = text
    }

    /**
     * It logs in a user
     */
    const login = ({ mail, password }) => loginUser({ mail, password })
        .then(res => {
            if (!res.token) return logMessgae(res.response.data.message)
            trackLogSign('login')({})
            close(false)
            return onComplete(true)

        })
        .catch(console.warn)

    /**
     * It takes a data object, sends it to the registerUser function, then if the response is
     * successful, it sends the email and password to the loginUser function, and if the login is
     * successful, it closes the modal and calls the onComplete function
     */
    const register = data => registerUser(data)
        .then(res => {
            if (res.response && res.response.data.message) return logMessgae(res.response.data.message)
            loginUser({ mail: data.email, password: data.password })
                .then(res => {
                    if (!res.token) return logMessgae('Oups, il semblerait que nous ayons un problème avec ton inscription. Réessaye plus tard.')
                    trackLogSign('signin')({})
                    close(false)
                    onComplete(false)
                })
        })

    /**
     * It takes an email address, sends it to the server, and then logs a message to the user
     */
    const forgot = ({ email }) => forgotPassword({ email })
        .then(res => {
            if (res?.response?.data?.message) return logMessgae(res.response.data.message)
            logMessgae(`<div className='reset-response'><p className='highlighted-reset-paragraph'>${t('reset.success')}</p><p>${t('reset.successPhrase')}</p></div>`)
        })

    /**
     * It takes the values of the inputs, and depending on the current step, it either logs in,
     * registers, or resets the password.
     */
    const onBigCuttonClick = () => {
        /* It's getting all the inputs in the login popup, and then it's creating an object with the
        name of the input as the key, and the value of the input as the value. */
        const inputs = [...document.querySelectorAll('.login-popup input')]
            .reduce((prev, cur) => {
                prev[cur.name || cur.id] = cur.type === 'checkbox' ? cur.checked : cur.value
                return prev
            }, {})
        /* A switch statement that is checking the value of the variable step. */
        switch (step) {
            /* It's checking if the inputs are empty, and if they are, it breaks. If they aren't, it
            calls the login function with the inputs as an argument. */
            case "connexion": {
                if (!inputs.mail || !inputs.password) break
                login(inputs)
                break
            }
            /* Checking if the email is valid and if the password is valid. */
            case "inscription": {
                /* It's checking if the email is valid. */
                inputs.email ? inputs.mail = inputs.email : console.log("MICHEL PLATINI")
                /* It's checking if the email is valid. */
                if (!inputs.email || !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputs.email))
                    logMessgae('Veuillez renseigner une adresse email valide.')
                /* It's checking if the password is valid. */
                else if (!inputs.password) logMessgae('Veuillez choisir un mot de passe.')
                /* Checking if the password and password_check are the same. */
                else if (inputs.password !== inputs.password_check) logMessgae('Les mots de passes sont différents.')
                /* Checking if the user has accepted the cgu. */
                else if (!inputs.cgu) logMessgae('Vous devez accepter les cgu actuelles.')
                /* Checking if the email is valid and if it is, it will set the register info and go to
                the next step. */
                else testEmail(inputs.mail).then(res => {
                    if (res.response.data.message.includes('Mot de passe')) return logMessgae('Il existe déjà un utilisateur avec cet email.')
                    setRegisterInfo(inputs) || setStep('inscription-2')
                })
                break
            }
            /* Checking if the user has filled in all the fields. */
            case "inscription-2": {
                /* Checking if the registerInfo is empty. If it is, it will return the setStep function with the
                parameter "inscription". */
                if (!registerInfo) return setStep("inscription")
                /* Checking if any of the values in the inputs object are empty strings. */
                if (Object.values(inputs).includes("")) logMessgae("Tous les champs sont obligatoires.")
                /* Checking if the zip code is valid. */
                else if (!/^(?:[0-8]\d|9[0-8])\d{3}$/.test(inputs.zip_code)) logMessgae("Le code postal n'est pas conforme.")
                /* Checking if the user is registering with a username or email. If it is a username, it will check if
                the username is already taken. If it is an email, it will check if the email is already taken. If
                the username or email is not taken, it will register the user. */
                else register(Object.assign({}, registerInfo, inputs))
                break
            }
            /* Checking if the email is valid. */
            case "reset": {
                /* Checking if the email is valid. */
                if (!inputs.email || !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(inputs.email))
                    logMessgae('Veuillez renseigner une adresse email valide.')
                /* Checking if the user has entered the correct password. If they have, it will log
                "You have successfully logged in." If they have not, it will log "Sorry, try again." */
                else forgot(inputs)
                break
            }
            default: break
        }
    }

    /* A React component that is used to display the login popup. */
    return <Popup open={open} className='login-popup' close={close}>
        <div className='upperbar'>
            <img src="/img/long-left-arrow.webp" className='back-arrow' onClick={() => close(false)} />
            <img alt="timenjoy logo" src="/img/logo.webp" className="login-tnj-logo" onClick={() => history.push('/home') || close(false)} />
        </div>
        <img className="login-close" onClick={() => close(false)} src='/img/cross.svg' />
        <div className='login-bg' />
        <div className='top-row left'>
            <h2>{step === "connexion" ? t('connexion.title') : step === "inscription" || step === "inscription-2" ? t('inscription.title') : t('reset.title')}</h2>
            <p>{step === "connexion" ? t('connexion.subtitle') : step === "inscription" || step === "inscription-2" ? t('inscription.subtitle') : t('reset.subtitle')}</p>
            <Form />
            <span ref={topLeft} className='error-message'></span>
        </div>

        <div className='top-row right'>
            <img className='quote' src='/img/quote.svg' />
            <p>{t('popup.message')}</p>
        </div>

        <div className='bottom-row left'>
            <BigButton text={step === "connexion" ? t('connexion.bigButton') : step === "inscription" || step === "inscription-2" ? t('inscription.bigButton') : t('reset.bigButton')} onClick={onBigCuttonClick} className='action-button' />
            <span>{step === "connexion" ? t('connexion.bottomText') : step === "inscription" || step === "inscription-2" ? t('inscription.bottomText') : t('reset.bottomText')}<span onClick={() => setStep(a => a === "connexion" ? 'inscription' : "connexion")} className='link'>{step === "connexion" ? t('connexion.bottomLink') : step === "inscription" || step === "inscription-2" ? t('inscription.bottomLink') : t('reset.bottomLink')}</span></span>
        </div>

        <div className='bottom-row right'>
            <FacebookLogin
                appId="423482784661112"
                autoLoad={false}
                fields="first_name, last_name, email,picture.type(square).width(400).height(400)"
                scope='public_profile,email'
                isMobile={false}
                callback={data => facebookLogin(data).then(() => { close(false); return onComplete(true) }).catch(() => logMessgae("Une erreur s'est produite"))}
                render={({ onClick }) => <BigButton type='light' className='action-button' onClick={onClick}>
                    <img src='/img/facebook-login.webp' className='fb-login-icon' width='20' height='20' /><span>Connexion Facebook</span>
                </BigButton>}
            />
        </div>
    </Popup>
}

// Refone Style
export default Loginv2
