const initialState = {
  likedEvents: []
};

export const eventData = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LIKED_EVENTS": {
      return {
        ...state,
        likedEvents : action.data
      }
    }
    case "REMOVE_LIKED_EVENTS": {
      return {
        ...state, 
        likedEvents: state.likedEvents.filter(event => event.id !== action.data.id)
      }
    }
    case "ADD_LIKED_EVENT": {
      return {
        ...state,
        likedEvents: state.likedEvents.concat(action.data)
      }
    }
    case 'RESET_LIKED_EVENTS': {
      return {}
    }
    default:
      return state;
  }
};
