import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/index';
import { initialize } from './service/AnalyticService'

window.location.pathname.includes('event') || initialize()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// if(process.env.REACT_APP_MODE !== "development") {
//   console.info = ()=>{}
//   console.log = ()=>{}
// }
// console.dev = (...args) => process.env.NODE_ENV !== "development" && console.log(...args)
// console.prodLog = console.log
// console.log = (...a) => process.env.NODE_ENV === "development" && console.prodLog(...a)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
