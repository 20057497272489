import React from "react"
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import SuspenseRoute from "./component/SuspenseRoute"
import lazy from './helpers/lazy'
import RGPDService from './service/RGPDService'
import store from './store'
import { Provider } from 'react-redux';
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ResetPassword from "./view/ResetPassword"


const Home = React.lazy(() => lazy(() => import("./view/home")))
const SingleEvent = React.lazy(() => lazy(() => import("./view/single-event")))
const Results = React.lazy(() => lazy(() => import("./view/results")))
const Terms = React.lazy(() => lazy(() => import("./view/Terms/Terms")))
const CGU = React.lazy(() => lazy(() => import("./view/CGU/CGU")))
const Profile = React.lazy(() => lazy(() => import("./view/profile/profile-v2")))

const App = () => {
  const { t } = useTranslation()
  return (
    <>
      <Provider store={store}>
        <Router>
          <Helmet>
            {!document.title && <title>{t('title')}</title>}
          </Helmet>
          <Switch>
            <SuspenseRoute exact path={["/", "/home", "/sortir"]} component={Home} />
            <SuspenseRoute exact path={["/evenements", "/evenements/inevitable"]} component={Results} />
            <SuspenseRoute exact path={["/event/:id", "/event/:seo/:id", "/evenement/:id/:seo", "/evenement/:id"]} component={SingleEvent} />
            <SuspenseRoute exact path="/profil" component={Profile} />
            <SuspenseRoute exact path="/terms" component={Terms} />
            <SuspenseRoute exact path="/terms/cgu" component={CGU} />
            <SuspenseRoute exact path="/password-reset/:id" component={ResetPassword} />
            <Route path="/*" ><Redirect to='/' /></Route>
          </Switch>
          {!!window.axeptioSettings || <RGPDService />}
          <div id="popup-root" />
        </Router>
      </Provider>
    </>
  )
}

export default App;
