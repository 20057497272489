import React, {
    useState,
    useEffect,
    useRef
} from 'react'
import './cookie-banner.scss'
import {
    BigButton
} from '../../component/UI'
import { setCookie, getCookie } from '../../helpers/cookie'
import { Link } from 'react-router-dom'

const CookieBanneer = ({ consent, manage }) => {
    const ref = useRef(null)
    useEffect(() => {
        setTimeout(() => ref.current && ref.current.classList.add('show'), 1000)
    })
    const waitBefore = func => e => {
        ref.current && ref.current.classList.remove('show')
        setTimeout(() => func(e), 400)
    }
    return (
        <div className='tnj-cookie-banneer' ref={ref}>
            <span>
                {/* <h6>Une minute pour un Cookie ?</h6> */}
                <p>
                    Nous utilisons des cookies pour nous assurer du bon fonctionnement de notre site, pour personnaliser notre contenu et nos publicités, pour proposer des fonctionnalités disponibles sur les réseaux sociaux et afin d’analyser notre trafic. Consultez nos <strong><Link to="/terms/cgu#cookies">Conditions Générales d'Utilisation</Link></strong> pour plus d'informations.
                </p>
            </span>
            {/* <BigButton type='light' text="Je choisis le gout" onClick={manage} /> */}
            <BigButton text="Accepter" onClick={waitBefore(consent)} />
        </div>
    )
}

const RGPDService = () => {
    const [step, setStep] = useState(0) // 0=> nothing, 1=> cookie banneer, 2=> manage cookie
    const changeStep = newStep => value => {
        if (!Number.isInteger(newStep)) return
        // TODO : Do some verifications or store something in... the cookies 
        // ! TEST MODE
        const time = localStorage.getItem("test-mode-15min-consent")
        if (newStep === 1 && time) return // && (time > Date.now())) return
        else if (step === 1 && value) localStorage.setItem("test-mode-15min-consent", true)//Date.now() + (60000 * 15))

        setStep(newStep)
    }
    useEffect(() => {
        changeStep(1)()
    }, [true])
    switch (step) {
        case 1: {
            return (<CookieBanneer consent={() => changeStep(0)(true)} manage={changeStep(2)} />)
        }
        // Manage Cookie
        // case 2: {}
        default: {
            return (<></>)
        }
    }
}
export default RGPDService
