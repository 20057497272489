import React from 'react'
import './starRate.scss'

export const StarRate = ({ rate = 3, max = 5, min = 0, onChange }) => {
    const current = Math.max(rate, min)
    const clickOnStar = n => e => onChange(n, e)
    return (
        <div className="star-rate-component">
            {
                Object.keys(new Array(max).fill(1))
                    .map(a => <Star key={a} filled={a < current - 1 ? 1 : a >= current ? 0 : current - a} onClick={clickOnStar(a)} />)
            }
        </div>
    )
}

export const Star = ({ filled = false, onClick }) => {
    return (
        <div className={(filled ? "filled " : "") + "star"} onClick={onClick}>
            <div style={filled && filled !== 1 ? { width: (filled * 100).toString().slice(0, 2) + "%" } : {}} />
        </div>
    )
}