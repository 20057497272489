import Rubber from './rubber'

/*
 *
 * Composant pour le bandeau "Top tendance" vert en haut d'une card.
 * 
 * Doit être placé à la racine de la carte (ex: EventCard ou CitiesCard) ./rubber.js
 * Le parent (la card) doit avoir la propriété CSS 'position : relative' et le placement du bandeau est automatique
 * 
 * Composé de deux <div />, le premier ( .rubber-front ) contient le texte et s'affiche devant la card tandis que
 * le second ( .rubber-back ) est un carré positionné derrière la card pour donner l'impression que le bandeau s'enroule
 * autour de la card.
 * 
 * Reçois en props le text à afficher ( par défault "TOP TENDANCE") puis le passe en majuscule
 * 
 * Il y aura peut-être besoin de modifier la propriété CSS 'z-index' au sein de la card de sorte à ce que 
 * la classe .rubber-front soit devant la card et .rubber-back soit derrière
 * ( Par défault le bandeau est réglé pour une card avec un 'z-index : 2' et .rubber-front et .rubber-back à 3 et 1 )
 * 
 * Exemple en SCSS :
 *  .la-card {
 *      position: relative;
 *      z-index : 5;
 * 
 *      .rubber-front {
 *          z-index: 6; // +1 
 *      }
 *      .rubber-back {
 *          z-index: 4; // -1
 *      }
 *  }
 * 
 */

export default Rubber