
const standardSlugify = str => str
    .toLowerCase()
    .replace(/[iìíîïĩ]/giu, 'i').replace(/ñ/giu, 'n').replace(/[oòóôõöōő]/giu, 'o')
    .replace(/[aàáâãäα]/giu, 'a').replace(/ç/giu, 'c').replace(/[eèéêë]/giu, 'e')
    .replace(/[uùúûüũū]/giu, 'u').replace(/œ/gi, 'oe')
    .replace(/[^-% a-z0-9]/gi, '')
    .replace(/[\s-]+/g, '-')

export const getSeoString = event => {
    console.log("SEOOO", event)
    let referencing = '';
    if (event.address) {
        let city = event.address.replace(/^(.*,? )*[0-9]*,?\s([^,]*).*$/g, '$2');
        if (city.indexOf(',') > -1) {
            city = city.replace(/^([^,]*),.*$/g, '$1');
        }
        referencing += city.replace(/ /g, '-').toLowerCase();
    }
    if (event.title) {
        let title = event.title.replace(/^(.*,? )*[0-9]*,?\s([^,]*).*$/g, '$2');
        if (title.indexOf(',') > -1) {
            title = title.replace(/^([^,]*),.*$/g, '$1');
        }
        referencing +=  '-' + title.replace(/ /g, '-').toLowerCase();
    }
    let referencingSplitted = referencing.toLowerCase().split('-')
    if (event.categoriesName) {
        event.categoriesName.forEach(aCategory => {
          if (referencingSplitted.indexOf(aCategory.toLowerCase()) === -1) {
            referencing += '-' + aCategory.toLowerCase();
          }
        })
    }
    referencingSplitted = referencing.toLowerCase().split('-');
    let idxTag = 0;
    if (event.tags) {
        const theTags = [... new Set(event.tags.map(x => x.replace('#', '')))].sort();
        theTags.forEach(aTag => {
            if (idxTag < 3 && referencingSplitted.indexOf(aTag.replace(/[_& ]/gm, '-').toLowerCase()) === -1) {
                referencing += '-' + aTag.replace(/[_& ]/gm, '-').toLowerCase();
                idxTag += 1;
            }
        });
    }
    // referencing = [... new Set(referencing.toLowerCase().split('-'))].join('-')
    return standardSlugify(("sortir" + (referencing[0] !== "-" ? "-" : "") + referencing).toLowerCase())
}

// TODO : think about internationalization
export const month = [
    "jan.", "févr.", "mars", "avr.", "mai", "juin",
    "juill.", "août", "sept.", "oct.", "nov.", "déc."
]
const aDayInUnix = 86400000
const UNIXDay = unix => unix - Math.ceil(unix % aDayInUnix)
/**
 * time = true => just time
 * time = false => just date
 * time = undefined => date
 */
export const parseISODate = (start, end, time) => {
    const lng = localStorage.getItem('i18nextLng')
    let debut = new Date(start), fin = new Date(end), parsedDate
    if (time)
        parsedDate = `${debut.getHours().toString().padStart(2, '0')}h${debut.getMinutes().toString().padStart(2, '0')} - ${fin.getHours().toString().padStart(2, '0')}h${fin.getMinutes().toString().padStart(2, '0')}`
    else if (UNIXDay(debut) == UNIXDay(fin) && UNIXDay(debut) == UNIXDay(new Date()))
        parsedDate = lng === "fr" ? "Aujourd'hui" : "Today" + (time !== false ? ` à ${debut.getHours().toString().padStart(2, '0')}h${debut.getMinutes().toString().padStart(2, '0')}` : "")
    else if (debut.getTime() < Date.now())
        parsedDate = `${lng === "fr" ? "Jusqu'au" : "Until"} ${fin.getDate()} ${month[fin.getMonth()]} ${fin.getFullYear()}`
    else if ((Math.floor(debut.getTime() / 86400000) - Math.floor(fin.getTime() / 86400000)))
        parsedDate = `${debut.getDate()} ${month[debut.getMonth()]} ${debut.getFullYear() !== fin.getFullYear() ? debut.getFullYear() + " " : ""
            }au ${fin.getDate()} ${month[fin.getMonth()]} ${fin.getFullYear()}`
    else
        parsedDate = `${lng === "fr" ? "Le" : ""} ${fin.getDate()} ${month[fin.getMonth()]} ${fin.getFullYear()}`
    return parsedDate
}

// ! These regexp doesn't work on safari
export const regExp = [
    // /(?<=\d{5} )[^\d,]+,[^,\d]+$/,
    // /(?<=\d{5} )[^\d,]+$/,
    // /(?<=\S)+ [^\d,]+ [^\d]+$/
]
export const parseLocation = (location = "", place) => {
    let parsedLocation, theRegExp = regExp.find(a => a.test(location));
    if (theRegExp)
        parsedLocation = theRegExp.exec(location)[0]
    if (parsedLocation)
        parsedLocation = parsedLocation.split(', ').map(a => a.slice(0, 1).toLocaleUpperCase() + a.slice(1)).join(', ')
    else
        parsedLocation = place.includes(", France") ^ location.endsWith(" France") ? place + ", France" : place
    return parsedLocation
}
export default {
    getSeoString,
    parseISODate,
    parseLocation
}
