import React from 'react'
import './menumobile.scss'
import Popup from '../Wrappers/Popup'
import { Link } from 'react-router-dom'
import { scrollTo, isLogin } from '../../helpers/utils'
import { useTranslation } from 'react-i18next'

/**
 * It's a popup that displays a menu
 * @returns A popup with a list of links and a title
 */
const MenuMobile = ({ open, close }) => {
    const { t } = useTranslation()
    /**
     * If the close function returns false, then run the toDo function.
     */
    const withClose = toDo => e => close(false) || toDo(e)

    /* It's a popup that displays a menu */
    return <Popup open={open} close={close} className='menu-popup'>
        <h2>
            Menu
            <img className="menu-close" onClick={() => close(false)} src='/img/cross-dark.svg' />
        </h2>
        <ul>
            <span onClick={withClose(() => scrollTo({ element: '.trending-cities' }))}>{t('menu.villes')}</span>
            <Link to='/evenements'>{t('menu.evenements')}</Link>
            {isLogin() ?
                <Link to='/profil'>{t('menu.profile')}</Link> :
                <span onClick={withClose(() => document.querySelector('.mobile-icons:last-of-type')?.click())}>{t('menu.connexion')}</span>}
            <a href='https://www.blog.timenjoy.fr/'>Blog</a>
            <a href='https://mytimenjoy.timenjoy.fr'>Timenjoy Business</a>
        </ul>
    </Popup >
}

export default MenuMobile