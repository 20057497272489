import ApiSails from "../config/ApisailsRequest";
import { APISAILS_URL } from "../config/apiUrl";
import axios from 'axios'

let categories = window.categories ? Promise.resolve(window.categories) : false
export const getCategories = () => {
    if (!categories) return categories = ApiSails.get("/category?where={\"active\":\"true\"}", {})
        .then(res => ({
            count: res.count,
            rows: res.rows.map(a => ({
                id: a.id,
                name: a.name,
                picture: `${APISAILS_URL}/category/${a.id}/getImage/${a.name.replace(/ /g, '-').toLowerCase()}.jpg`,
                active: a.active
            }))
        }))
    return categories
}

export const getCityFromCoordinates = value => {
    return axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${value.lat}&lon=${value.lng}`)
}
export const sailsGetCityFromCoordinates = value => {
    return ApiSails.get(`${APISAILS_URL}/Geocoding/getCityFromCoordinate?lat=${value.lat}&lon=${value.lng}`)
}

export const getAutocomplete = value => {
    return axios.get(`https://nominatim.openstreetmap.org/search?city=${value}&format=json`)
}

let cities = window.tnjCities ? Promise.resolve(window.tnjCities) : false

const HARDCODEDCITIES = {
    featured: [
        {
            name: "Paris",
            picture: "/img/ville/Paris.jpg",
            top: true,
            coordinate: { lat: 48.8534, lng: 2.3488 }
        },
        {
            name: "Nice",
            picture: "/img/ville/Nice.jpg",
            coordinate: { lat: 43.7031, lng: 7.2661 }
        },
        {
            name: "Marseille",
            picture: "/img/ville/Marseille.jpg",
            coordinate: { lat: 43.3, lng: 5.4 }
        },
        {
            name: "Toulouse",
            picture: "/img/ville/Toulouse.jpg",
            coordinate: { lat: 43.6043, lng: 1.4437 }
        }
    ],
    list: [
        {
            name: "Lille",
            picture: "/img/ville/Lille.jpg",
            coordinate: { lat: 50.6333, lng: 3.0667 }
        },
        {
            name: "Bordeaux",
            picture: "/img/ville/Bordeaux.jpg",
            coordinate: { lat: 44.8333, lng: -0.5667 }
        },
        {
            name: "Montpellier",
            picture: "/img/ville/Montpellier.jpg",
            coordinate: { lat: 43.6, lng: 3.8833 }
        },
        {
            name: "Lyon",
            picture: "/img/ville/Lyon.jpg",
            coordinate: { lat: 45.75, lng: 4.85 }
        },
        {
            name: "Nantes",
            picture: "/img/ville/Nantes.jpg",
            coordinate: { lat: 47.2173, lng: -1.5534 }
        },
        {
            name: "Strasbourg",
            picture: "/img/ville/Strasbourg.jpg",
            coordinate: { lat: 48.5833, lng: 7.75 }
        },
        {
            name: "Rennes",
            picture: "/img/ville/Rennes.jpg",
            coordinate: { lat: 48.0833, lng: -1.6833 }
        },
        {
            name: "Grenoble",
            picture: "/img/ville/Grenoble.jpg",
            coordinate: { lat: 45.1667, lng: 5.7167 }
        },
        {
            name: "Dijon",
            picture: "/img/ville/Dijon.jpg",
            coordinate: { lat: 47.3167, lng: 5.0167 }
        },
        {
            name: "Nîmes",
            picture: "/img/ville/Nimes.jpg",
            coordinate: { lat: 43.8333, lng: 4.35 }
        }
    ]
}
export const getCities = () => {
    if (cities) return cities
    return cities = ApiSails.get("/city", {})
        .then(res => {
            const list = res.rows?.sort?.((a, b) => b.inevitable_rate - a.inevitable_rate).map(a => ({
                name: a.name?.split?.(',')?.[0] || a.name,
                picture: `${APISAILS_URL}/city/${a.id}/getImage/${/^\w*/.exec(a.name)[0]}.jpg`,//provisoire
                coordinate: { lat: a.lat, lng: a.lng },
                top: a.inevitable && a.inevitable_rate,
                radius: a.radius
            }))
            return {
                count: res.metaData?.count,
                featured: list.filter(a => a.top),
                list: list.filter(a => !a.top)
            }
        }).catch(err => console.error(err) || HARDCODEDCITIES)
}

const CategoryServices = {
    getCategories,
    getCities
}

export default CategoryServices
